import AddressCard from "components/CheckOutPage/AddressCard";
import CheckOutHeader from "components/CheckOutPage/CheckOutHeader";
import SectionGrid2 from "components/CheckOutPage/SectionGrid2";
import ShippingAddressCard from "components/CheckOutPage/ShippingAddressCard";
import ShippingAddressForm from "components/CheckOutPage/ShippingAddressForm";
import AddressGridIcon from "shared/Svg/AddressGrid";

const CheckOutAddress = () => {
  return (
    <>
      <main>
        <CheckOutHeader />
        <div className="flex justify-center mt-5 w-95 ">
          <AddressGridIcon />
        </div>
        <div className="container">
          <SectionGrid2 />
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6">
              {" "}
              <AddressCard />
            </div>
            <div className="col-span-12 lg:col-span-6">
              {" "}
              <ShippingAddressCard />
            </div>
          </div>

          <div className="mt-4 mx-2">
            <ShippingAddressForm />
          </div>
        </div>
      </main>
    </>
  );
};
export default CheckOutAddress;
