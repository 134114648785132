// store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ContainerData from "../containers/store"

export type RootState = ReturnType<typeof store.getState>;

// Combine all reducers without persistConfig
const rootReducer = combineReducers({

ContainerData: ContainerData,
});

// Use the regular rootReducer without persistConfig
const store = configureStore({
  reducer: rootReducer,
});

export default store;
