import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/PageCart/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import ShowAllParts from "containers/ShowAllParts/PageShowAllParts";
import PageShowAllCategory from "containers/PageShowAllCartegories/PageShowAllCartegories";
import PageOrderTracking from "containers/OrderTraking/PageOrderTraking";
import CheckOutAddress from "containers/CheckOutPage/CheckOutAdress";
import ResetPasswordModelScreen from "components/model/ResetPassword";
import AdvanceSearch from "containers/AdvanceSearch/AdvanceSearch";
import PageCompare from "containers/PageCompare/PageCompare";
import PageFavorites from "containers/PageFavorites/PageFavorites";
import PageHowToBuy from "containers/PageHowToBuy/PageHowToBuy";
import PageExchangeReturn from "containers/PageExchangeReturn/PageExchangeReturn";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/advance-search", component: AdvanceSearch },
  { path: "/compare", component: PageCompare },
  { path: "/favorites", component: PageFavorites },
  { path: "/show-all-recommended-products", component: ShowAllParts },
  { path: "/show-all-recommended-category", component: PageShowAllCategory },
  { path: "/order-tracking", component: PageOrderTracking },
  { path: "/product-detail", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/checkout-address", component: CheckOutAddress },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/forgot-password/:token", component: ResetPasswordModelScreen },
  { path: "/how-to-buy/:name", component: PageHowToBuy },
  { path: "/exchange-return", component: PageExchangeReturn },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
