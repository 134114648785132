import React, { useState } from "react";

import NcImage from "shared/NcImage/NcImage";

interface Props {}

function ChangeBrand(props: Props) {
  const {} = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const BrandData = [
    {
      id: 1,
      logo: "https://1000logos.net/wp-content/uploads/2020/06/Bajaj-Logo.png",
    },
    {
      id: 2,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 3,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
    {
      id: 4,
      logo: "https://logowik.com/content/uploads/images/royal-enfield6316.jpg",
    },
    {
      id: 5,
      logo: "https://1000logos.net/wp-content/uploads/2020/06/Bajaj-Logo.png",
    },
    {
      id: 6,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 7,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
    {
      id: 8,
      logo: "https://logowik.com/content/uploads/images/royal-enfield6316.jpg",
    },
    {
      id: 9,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 10,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
    {
      id: 11,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 12,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
    {
      id: 13,
      logo: "https://logowik.com/content/uploads/images/royal-enfield6316.jpg",
    },
    {
      id: 14,
      logo: "https://1000logos.net/wp-content/uploads/2020/06/Bajaj-Logo.png",
    },
    {
      id: 15,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 16,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
    {
      id: 17,
      logo: "https://logos-world.net/wp-content/uploads/2020/12/Hero-Logo.png",
    },
    {
      id: 18,
      logo: "https://logowik.com/content/uploads/images/197_suzuki.jpg",
    },
  ];
  const [selectedBrandId, setSelectedBrandId] = useState(1);
  const [selectedBrandIdOuter, setSelectedBrandIdOuter] = useState(1);

  const handleBrandClick = (id: any) => {
    setSelectedBrandId(id);
  };
  const handleSubmit = () => {
    setSelectedBrandIdOuter(selectedBrandId);
    closeModal();
  };
  return (
    <>
      <div className="flex gap-1 items-center h-5">
        <NcImage
          src={BrandData[selectedBrandIdOuter - 1]?.logo}
          className="w-10 "
          containerClassName="w-10"
        />
        <span className="font-semibold cursor-pointer" onClick={openModal}>
          Change Brand
        </span>
      </div>

      {modalIsOpen && (
        <>
          <div className=" container   flex  items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  ">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none product-preview-container px-10 py-10  ">
              {/* <div className="grid lg:grid-cols-12 md:grid-cols-6 sm:grid-cols-3 gap-4 p-10"> */}
              <div className="grid lg:grid-cols-12 md:grid-cols-3 sm:grid-cols-2 gap-1 grid-cols-3 lg:p-10  lg:gap-4">
                {BrandData.map((value) => (
                  <div
                    className={`lg:col-span-2 md:col-span-1 sm:col-span-1 col-span-1 p-2 border-2 ${
                      selectedBrandId === value.id
                        ? "border-blue-500"
                        : "border-black"
                    }`}
                    key={value.id}
                    onClick={() => handleBrandClick(value.id)}
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    <NcImage
                      src={value.logo}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>

              <div className="flex justify-center gap-5 mt-5 ">
                <button
                  className="rounded-full bg-white text-black border px-3 py-2 w-44 border-black"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="rounded-full bg-black text-white  px-3 py-2 w-44 "
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ChangeBrand;
