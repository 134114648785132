// import React from "react";

function CartStarLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#E6EFFB"></circle>
      <g clipPath="url(#clip0_1153_41916)">
        <path
          fill="#1071FF"
          d="M18.667 10.16l-4.794-.414L12 5.333l-1.873 4.42-4.794.407 3.64 3.153L7.88 18 12 15.513 16.12 18l-1.087-4.687 3.634-3.153zM12 14.266L9.493 15.78l.667-2.854-2.213-1.92 2.92-.253L12 8.066l1.14 2.694 2.92.253-2.213 1.92.666 2.853L12 14.266z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1153_41916">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(4 4)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CartStarLogo;