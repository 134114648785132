import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const VerifyEmail: any = createAsyncThunk(
  "/verifyEmail",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/generate-email-otp`, data);

      return { verifyEmail: response?.data };
    } catch (error) {
      console.error("Error in verifyEmail:", error);
      throw error;
    }
  }
);

export const VerifyEmailOTP: any = createAsyncThunk(
  "/verifyEmailOtp",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/verify-otp`, data);

      return { verifyEmailOTP: response?.data };
    } catch (error) {
      console.error("Error in verifyEmailOTP:", error);
      throw error;
    }
  }
);

export const VerifyMobileOTP: any = createAsyncThunk(
  "/VerifyMobileOTP",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/verify-otp`, data);

      return { verifyMobileOTP: response?.data };
    } catch (error) {
      console.error("Error in verifyEmailOTP:", error);
      throw error;
    }
  }
);

export const VerifyMobile: any = createAsyncThunk(
  "/verifyMobile",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/generate-mobile-otp`, data);

      return { verifyMobile: response?.data };
    } catch (error) {
      console.error("Error in verifyMobile:", error);
      throw error;
    }
  }
);
export const GetAllBrand: any = createAsyncThunk("/GetAllBrand", async () => {
  try {
    const response = await axios.get(`${baseUrl}/brands-getall`);

    return { getAllBrand: response?.data };
  } catch (error) {
    throw error;
  }
});

export const Register: any = createAsyncThunk("/Register", async (data) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.post(`${baseUrl}/register`, data);

    // console.log(response?.data, 'res')
    return { register: response.data };
  } catch (error: any) {
    return { register: error.response.data };
  }
});
export const SetLocalStorage: any = createAsyncThunk("/SetLocalStorage", () => {
  return {
    token: localStorage.getItem("token"),
    userData: localStorage.getItem("userData"),
  };
});

export const Login: any = createAsyncThunk("/Login", async (data) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.post(`${baseUrl}/login`, data);

    // console.log(response?.data, 'res')
    return { login: response.data, success: true };
  } catch (error: any) {
    let message = error.response.data.message;
    return { login: error.response.data, message, success: false };
  }
});

export const ContainerDataSlice = createSlice({
  name: "ContainerData",
  initialState: {
    verifyMobile: [],
    verifyEmail: [],
    verifyEmailOTP: [],
    verifyMobileOTP: [],
    getAllBrand: [],
    userData: null,
    token: null,
    changeBrandOpen: false,
  },
  reducers: {
    ChangeBrandModalOpen: (state, action) => {
      state.changeBrandOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(VerifyEmail.fulfilled, (state, action: any) => {
        state.verifyEmail = action.payload.verifyEmail;
      })
      .addCase(VerifyMobile.fulfilled, (state, action: any) => {
        state.verifyMobile = action.payload.verifyMobile;
      })
      .addCase(VerifyEmailOTP.fulfilled, (state, action: any) => {
        state.verifyEmailOTP = action.payload.verifyEmailOTP;
      })
      .addCase(VerifyMobileOTP.fulfilled, (state, action: any) => {
        state.verifyMobileOTP = action.payload.verifyMobileOTP;
      })
      .addCase(GetAllBrand.fulfilled, (state, action: any) => {
        state.getAllBrand = action.payload.getAllBrand;
      })
      .addCase(Register.fulfilled, (state, action: any) => {
        if (action.payload.register.success) {
          localStorage.setItem("token", action.payload.register.data.token);
          localStorage.setItem(
            "userData",
            JSON.stringify(action.payload.register.data.user)
          );
          state.userData = action.payload.register.data.user;
          state.token = action.payload.register.data.token;
        }
      })
      .addCase(Login.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          localStorage.setItem("token", action.payload.login.token);
          localStorage.setItem(
            "userData",
            JSON.stringify(action.payload.login.user)
          );
          state.userData = action.payload.login.user;
          state.token = action.payload.login.token;
        }
      })
      .addCase(SetLocalStorage.fulfilled, (state, action: any) => {
        state.token = action.payload.token;
        state.userData = JSON.parse(action.payload.userData);
      });
  },
});

export const { ChangeBrandModalOpen } = ContainerDataSlice.actions;

export default ContainerDataSlice.reducer;
