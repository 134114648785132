import { useState } from "react";
import axios from 'axios';
import swl from 'sweetalert2'
interface ForgetPasswordProps {
  onPassback: (data: string) => void;
  setShowModal :any
  }
const ForgetPasswordScreen: React.FC<ForgetPasswordProps> = ({ onPassback, setShowModal }) => {
    const [mail, setMail] = useState('')
    const [loading, setLoading] = useState(false)
  //   const [ResetPasswordShowModel, setResetPasswordShowModel] = useState("");

  const sendDataToParent = () => {
    // Call the callback function with the data you want to pass back
    onPassback('ResetPasswordModel');
  };
    const baseUrl = process.env.REACT_APP_BASE_URL_ADMIN
    const sendLink = async () => {
      setLoading(true)
      await axios.post(`${baseUrl}/forgot-password`, { email: mail })
        .then((res) => {
          setShowModal(false);
          swl.fire({
            title: 'Success',
            text: 'Please check your mail for reset password link',
            icon: "success"
          })
        })
        .catch((error) => {
          swl.fire({
            title: 'Error',
            text: error.response.data && error.response.data.message ? error.response.data.message : 'Something went wrong',
            icon: "error"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }

  return (
    <>
        <div className="ForgetPasswordModel">
          <div>
            <p className="text-center font-bold text-2xl">
              Did you forget the password ?
            </p>
            <p className="text-center m-5">
              Enter the e-mail address in the field below. <br />
              We will send a link to change your password to your e-mail.
            </p>
          </div>

          {/*header*/}
          <div className=" mx-10">
            <div className=" flex items-start justify-start mt-5 ">
              <label>E-mail</label>
            </div>
            <input
              type="text"
              className="w-full border-[#EAECEE] mt-2 rounded-md px-4 py-2"
              placeholder=""
              value={mail}
              onChange={(e)=>setMail(e.target.value)}
            />
          </div>
          {/*body*/}
          <div className="relative p-6  flex justify-center">
            <button
            className="rounded-full w-full p-3 bg-[#151515] text-white"
            onClick={sendLink}
            disabled={loading}
            >
              {loading ? 'Loading....' :'Reset your password' }
            </button>
          </div>
          {/*footer*/}
        </div>
    </>
  );
};
export default ForgetPasswordScreen;
