import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import ContactUsCircle from "shared/Svg/ContactUsCircle";
import InstagramLogoContact from "shared/Svg/InstagramLogoContact";
import LocationIconContact from "shared/Svg/LocationIconContact";
import MailIconContact from "shared/Svg/MailIconContact";
import PhoneIcon from "shared/Svg/PhoneIcon";
import TweeterLogo from "shared/Svg/tweeterLogo";
import ContactUsForm from "./ContactUsForm";

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <>
      <Helmet>
        <title>Contact </title>
      </Helmet>
      <div className="container py-10">
        <Breadcrumb item1="Home" item1link="/" item2="Help" item3="Contact" />
        <h1 className="text-center font-medium text-2xl lg:text-4xl py-5 lg:pb-10">
          Contact Us
        </h1>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 lg:col-span-5">
            <div className="contact-us-card">
              <div className="absolute bottom-0 right-0">
                <ContactUsCircle />
              </div>
              <div className="absolute bottom-5 left-10 flex gap-5">
                <TweeterLogo />
                <InstagramLogoContact />
              </div>
              <h1 className="text-white text-3xl font-semibold">
                Contact Information
              </h1>
              <p className="font-normal text-base text-white py-2">
                Say something to start a live chat!
              </p>
              <div className="mt-20">
                <div className="grid grid-cols-12 mb-5 items-center ">
                  <div className="col-span-1">
                    <PhoneIcon />
                  </div>
                  <div className="col-span-11">
                    <h1 className="text-white">+91 987654321</h1>
                  </div>
                </div>
                <div className="grid grid-cols-12 mb-5 items-center ">
                  <div className="col-span-1">
                    <MailIconContact />
                  </div>
                  <div className="col-span-11">
                    <h1 className="text-white">demo@gmail.com</h1>
                  </div>
                </div>
                <div className="grid grid-cols-12 mb-5 items-center ">
                  <div className="col-span-1">
                    <LocationIconContact />
                  </div>
                  <div className="col-span-11">
                    {" "}
                    <h1 className="text-white">
                      132 Dartmouth Street Boston, Massachusetts 02156 United
                      States
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-7">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageContact;
