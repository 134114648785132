
const PhoneLogo =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15"
      height="25"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path fill="url(#pattern0)" d="M0 0H15V15H0z"></path>
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01111)" xlinkHref="#image0_353_77043"></use>
        </pattern>
        <image
          id="image0_353_77043"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEvUlEQVR4nO2cW4hcRRCGTzQGNZmpOntRV+KDl4gIwfgSxBsI4gVFBDGoEIOgERM3UzWToL44Cr4EMRjxSQQvEUUNeVAiCoKCYuKDSqIPgsYrQTEXxFsSTfaX3t0Q0Nn1zLlYfXr6g3rcmf7/7anTp7q6kyQSiUQikUgkEolEBoaVOIEFy1nxJgv2sGAfKV4YGsdC66EFQ1NwFSt2sQL/DBJ8M7+DU6zHWG+6OI4VG1gx0ctkPmb2S9ZDrS9dzGPB5tkM5mMxwYLLrIdcP27G8ax4OaPJmJ7VnyZdzLUeeq1gxfp+TObpSNu4x3rstSEVXPdfOXmW2NtUDFlr8J6xlTiZBV/lNBmTKUTxpLUO72HFA0VM5qk4nCoWW2vxly7mkWB3CUaDFe9Yy/GWZgtXl2QyXDQFy6w1eQkpHi/TaFJ863K+tS7vIMF7ZRrNU2vrh611eQcJfqjA6APUwZnW2ryCFQfLNppdCDZba/MKEvxRidEKuAettT5vcG91VRlNis9iHeSo0YIPqzKap/L1nbZTyRNIsKlKo1nwtrVGL2DB6oqN/thaoxc02ji3YqOfstboDUUrdzyzyX8NdXC+tT5vIMVDFc3oDdbavCJt4wxX5izZ5B/T+0DW2ryDBFtLThvLrTV5SdrGxWWZTIr3kwRzrDV5CyveKsHow9TCEmstXjOkuKiElPG0tY5aQIoXixidCi6x1lALRgRjJPg5d37uxDp0ZlgwnntWt3FDlRMhvNawnFU9Unzg/t5aQm1orsXZJPgl58xebz3+WkGKuwqspe+wHn+tYMEruYwWHGgqllqPvzY0VmOYFV/nnNXfu1WMtYbawIoLSPF7zpm9k+5Haq2hNqSK23Lna8G2UzuYb62hNrDgsQJmvx53xLPSxdzJY3Ca2+znYlUvI8Pr0HAbrgWWfU9EszMyIhjLuxKZntmb3EHRzL+kQaapOIcVPxXJ2bG9NyNpG5cW6d1zLcPUAlc7JQIhbeFaFhwqkLN3DK/D6bm+W7GYBc+4Hj93rMOVDIJe2VALN7LizwIze3c/r+vuV0CCja5vpMfnfe7++UmokOKmGYRnjYMsWDH7t2AOK253rQxZngEkOCsJERasYMGRAmY7gzb2qmdTGxe63fW+Pk9wyH3e6CosSEKjqbi7BLO3uXTUWINFpLiCFM8WafBxB5iCPC2WurpIsTRSSZDgXVcgS0KiKVhW5AFZWQiOkOL5BeMYTUKB27jebQCYm9vb8P0saAWzv5kqrqnyQFIJ8Qm3cHkSyhskC/Z5YOpMMeGuLQrijqjhNs4rUoj6P8KtToK4/Wxkqur3kddmu8piMPVszb95UHkI9iRB7dRI/m2ximNvEhqp4hYS/OZZ6tiahAi1sMSnh2Tawq1J0E06gi3WJrPgi6Dr2UdxJVBS/Gpk9IS7izUZFBprsIgV2w2MfjQZ0Ks77y1y8qDPeNVdfJsMKqNrcZqrtBW4YTJLXt6SdHGitVYvaCqWkuKNsnMyCR4Z6Jk82/E8UrxWwnHqXfEaogy4wg8rHmTBl/0YTILvSNFZqDgpy/dE/t0xtcrlcrfXOLlDPlXU3++Mdf0e7nWfBFfGNrRIJBKJRCKRSCQSiUQikaQnfwPzsqvSsyHw0QAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
    
    </>)
}

export default PhoneLogo