import React from "react";
import SearchIcon from "shared/Svg/SearchIcon";
import { useForm } from "react-hook-form";
import Select from "react-select";
function AdvanceSearch() {
  const SearchByModelOptions = [
    { value: "TVS Apache RTR 160", label: "TVS Apache RTR 160" },
    { value: "TVS Apache RTR 180", label: "TVS Apache RTR 180" },
  ];
  const SearchByPartCategory = [
    { value: "Silencer Guard", label: "Silencer Guard" },
    { value: "Leg Guard", label: "Leg Guard" },
  ];
  const SearchByPartNumber = [
    { value: "0086010", label: "0086010" },
    { value: "00860101", label: "00860101" },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data: any) => {
    // Perform search with data
    console.log("Searching...", data);
  };
  const handleSelectChange = (fieldName: any, selectedOption: any) => {
    setValue(fieldName, selectedOption); // Update form value
  };
  return (
    <>
      <div className="container">
        <div className="flex flex-wrap mt-10  gap-5">
          <div className="search-container-modal">
            <SearchIcon />
            <input
              type="text"
              className="input-search-modal"
              placeholder="Search by product or SKU"
            />
          </div>
          <button className="bg-[#151515] rounded-lg text-white mx-2   search-btn-modal">
            Search
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-5">
            <Select
              {...register("model", { required: true })}
              options={SearchByModelOptions}
              className="w-80"
              placeholder="Search by model"
              onChange={(selectedOption) =>
                handleSelectChange("model", selectedOption)
              }
            />
            {errors.model && (
              <span className="text-red-500">Model is required</span>
            )}
          </div>
          <div className="my-5">
            <Select
              {...register("category", { required: true })}
              options={SearchByPartCategory}
              className="w-80"
              placeholder="Search by part category"
              onChange={(selectedOption) =>
                handleSelectChange("category", selectedOption)
              }
            />
            {errors.category && (
              <span className="text-red-500">Category is required</span>
            )}
          </div>
          <div className="my-5">
            <Select
              {...register("partNumber", { required: true })}
              options={SearchByPartNumber}
              className="w-80"
              placeholder="Search by part number"
              onChange={(selectedOption) =>
                handleSelectChange("partNumber", selectedOption)
              }
            />
            {errors.partNumber && (
              <span className="text-red-500">Part number is required</span>
            )}
          </div>

          <div className="flex justify-center my-5">
            <button
              type="submit"
              className="bg-[#151515] rounded-lg text-white mx-2 search-btn-modal"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AdvanceSearch;
