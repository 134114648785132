import React from "react";
import { useForm, Controller } from "react-hook-form";

interface Props {}

function ContactUsForm(props: Props) {
  const {} = props;
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    console.log(data, "OrderTracking Page");
  };
  return (
    <>
      {" "}
      <div className="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" grid grid-cols-12 gap-5">
            <div className="col-span-6">
              <label className="font-semibold 	"> First Name</label> <br />
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First Name is required",
                }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Your First Name"
                    className={`mt-2 w-full rounded-full    ${
                      errors.firstName ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.firstName &&
                typeof errors.firstName?.message === "string" && (
                  <div className=" ">
                    <span className="text-red-500  ">
                      {errors.firstName?.message}
                    </span>
                  </div>
                )}
            </div>
            <div className="col-span-6">
              <label className="font-semibold 	">Last Name</label> <br />
              <Controller
                name="LastName"
                control={control}
                rules={{ required: "Last Name is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Your Last Name"
                    className={`mt-2 w-full rounded-full  ${
                      errors.name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.LastName &&
                typeof errors.LastName?.message === "string" && (
                  <div className=" ">
                    <span className="text-red-500 ">
                      {errors.LastName?.message}
                    </span>
                  </div>
                )}
            </div>
            <div className="col-span-6">
              <label className="font-semibold 	"> Email</label> <br />
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    className={`mt-2 w-full rounded-full  ${
                      errors.name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.email && typeof errors.email?.message === "string" && (
                <div className=" ">
                  <span className="text-red-500 ">{errors.email?.message}</span>
                </div>
              )}
            </div>
            <div className="col-span-6">
              <label className="font-semibold">Phone Number</label> <br />
              <Controller
                name="Phone"
                control={control}
                rules={{ required: "Phone Number is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Enter Your Phone Number"
                    className={`mt-2 w-full rounded-full  ${
                      errors.Phone ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.Phone && typeof errors.Phone?.message === "string" && (
                <div className=" ">
                  <span className="text-red-500 ">{errors.Phone?.message}</span>
                </div>
              )}
            </div>
            <div className="col-span-12">
              <label className="font-semibold ">Message</label> <br />
              <Controller
                name="Message"
                control={control}
                rules={{ required: "Message is required" }} // Add required rule here
                render={({ field }) => (
                  <textarea
                    placeholder="Write your message"
                    className={`mt-2 w-full rounded-full p-4  ${
                      errors.name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.Message &&
                typeof errors.Message?.message === "string" && (
                  <div className=" ">
                    <span className="text-red-500 ">
                      {errors.Message?.message}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="flex justify-end mt-5 ">
            <button
              className="bg-black text-white rounded-full p-3 "
              type="submit"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactUsForm;
