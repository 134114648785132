import React from "react";

interface Props {}

function FastDelivery(props: Props) {
  const {} = props;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        fill="none"
        viewBox="0 0 60 60"
      >
        <path
          fill="red"
          d="M30.96 24c-.26.008-.51.116-.694.304l-5.276 5.26-3.25-3.24c-.91-.948-2.38.516-1.43 1.424l3.966 3.956a1.013 1.013 0 001.43 0l5.99-5.974c.66-.64.174-1.73-.734-1.73h-.002zM1 32h6c.554 0 1 .446 1 1s-.446 1-1 1H1c-.554 0-1-.446-1-1s.446-1 1-1zm0-8h6c.554 0 1 .446 1 1s-.446 1-1 1H1c-.554 0-1-.446-1-1s.446-1 1-1zm0-8h6c.554 0 1 .446 1 1s-.446 1-1 1H1c-.554 0-1-.446-1-1s.446-1 1-1zm48 22c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3zm-26-2c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3zM13 12c-1.644 0-3 1.356-3 3v22c0 1.644 1.356 3 3 3h4c1.352.02 1.352-2.02 0-2h-4c-.572 0-1-.428-1-1V15c0-.572.428-1 1-1h26c.572 0 1 .428 1 1v23H29c-1.32 0-1.296 2.02 0 2h14c1.32 0 1.308-2 0-2h-1V20h8.454L58 31.792V37c0 .572-.428 1-1 1h-2c-1.308 0-1.308 2 0 2h2c1.644 0 3-1.356 3-3v-5.5c0-.19-.054-.38-.156-.54l-8-12.5c-.184-.286-.5-.46-.844-.46h-9v-3c0-1.644-1.356-3-3-3H13z"
        ></path>
      </svg>
    </>
  );
}

export default FastDelivery;
