import NcInputNumber from "components/NcInputNumber";
import React, { useState } from "react";
import NcImage from "shared/NcImage/NcImage";

interface Props {
  data: any;
}

function FavoritesProductTable(props: Props) {
  const { data } = props;
  const [quantities, setQuantities] = useState<any>({});
  console.log(quantities, "q");
  const handleQuantityChange = (id: any, quantity: any) => {
    setQuantities((prevQuantities: any) => ({
      ...prevQuantities,
      [id]: quantity,
    }));
  };

  return (
    <>
      {" "}
      <div className="table-responsive">
        <table className="table-auto w-full border">
          <thead className="border">
            <tr style={{ borderBottom: "2px solid black" }}>
              <th className="p-3" style={{ width: "300px" }}>
                Part Name
              </th>
              <th>Quantity</th>
              <th>Age of parts</th>
              <th>Price net</th>
              <th>Price gross</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((value: any) => (
              <>
                <tr className="border">
                  <td>
                    <div className="flex items-start p-4">
                      <div className="w-28">
                        <NcImage
                          src={value?.image}
                          containerClassName="w-28"
                          className="object-cover"
                        />
                      </div>
                      <div className="">
                        <h1 className="font-bold">{value?.name}</h1>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center ">
                      <p className="text-start   text-sm lg:text-base mt-1">
                        Item:
                      </p>
                      <span className="mx-2">
                        <NcInputNumber
                          defaultValue={quantities[value.id] || 1}
                          onChange={(quantity) =>
                            handleQuantityChange(value.id, quantity)
                          }
                        />
                      </span>
                    </div>
                  </td>
                  <td className="text-center">{value?.Age}</td>
                  <td className="text-center"> ₹ {value?.price}</td>
                  <td className="text-center"> ₹ {value?.priceGross}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FavoritesProductTable;
