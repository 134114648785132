import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import React from "react";
import PrinterIcon from "shared/Svg/PrinterIcon";
import TrashIcon from "shared/Svg/TrashIcon";
import ShareIcon from "shared/Svg/share";
import FavoritesProductTable from "./FavoritesProductTable";
import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";

interface Props {}

function PageFavorites(props: Props) {
  const {} = props;
  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      priceGross: 85,
      image: p1,
      Age: "Last 30 days",
    },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      Age: "365+ days",
      priceGross: 89,
      image: p2,
    },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      Age: "Last 30 days",
      image: p3,
      priceGross: 98,
    },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      Age: "Last 30 days",
      priceGross: 98,
      image: p4,
    },
    {
      id: 5,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      Age: "Last 30 days",
      priceGross: 98,
      image: p2,
    },
    {
      id: 6,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      Age: "Last 30 days",
      priceGross: 98,
      image: p3,
    },
    {
      id: 7,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      Age: "182+ days",
      priceGross: 98,
      image: p4,
    },
    {
      id: 8,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      Age: "Last 30 days",
      priceGross: 98,
      image: p2,
    },
  ];

  return (
    <>
      <div className="container py-5">
        <Breadcrumb
          item1="Home"
          item1link="/"
          item2="Favorites"
          item2link="/favorites"
        />

        <div className="flex py-5">
          <h1 className="text-sm lg:text-4xl font-medium">
            Favorites (10 products)
          </h1>
          <div className="ml-auto flex gap-2 items-center">
            <TrashIcon /> <span> Remove All </span>
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex gap-2 items-center">
            <PrinterIcon /> <span>Print Page</span>
          </div>
          <div className="flex gap-5 ml-auto">
            <button className="bg-black text-white rounded-full lg:py-2 lg:px-5">
              Add to all favorites to cart
            </button>
            <button>
              <ShareIcon />
            </button>
          </div>
        </div>
        <div className="py-5">
          <FavoritesProductTable data={ProductData} />
        </div>
      </div>
    </>
  );
}

export default PageFavorites;
