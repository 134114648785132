import React, { FC } from "react";

interface Props {
  status: any;
  className?: string;
}

const ProductDiscount: FC<Props> = ({
  status = "-50%",
  className = "absolute top-5 left-0 px-1.5 py-1.5 text-base bg-[#FF7E27] dark:bg-slate-900 text-white  dark:text-slate-300",
}) => {
  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES = `nc-shadow-lg  flex items-center justify-center ${className}`;

    return (
      <div className={CLASSES}>
        <span className="ml-1 leading-none">{status}</span>
      </div>
    );
  };

  return renderStatus();
};

export default ProductDiscount;
