import React from "react";

interface Props {}

function MailIconContact(props: Props) {
  const {} = props;

  return (
    <>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          fill="#fff"
          d="M20 0H0v16h20V0zm-2 4l-8 5-8-5V2l8 5 8-5v2z"
        ></path>
      </svg>
    </>
  );
}

export default MailIconContact;
