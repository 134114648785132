import React, { FC, useEffect, useState } from "react";
import MenuBar from "shared/MenuBar/MenuBar";
import Navigation from "shared/Navigation/Navigation";
import { useLocation } from "react-router-dom";
import FaceBookLogo from "shared/Svg/FaceBookLogo";
import InstagramLogo from "shared/Svg/InstagramLogo";
import TwiiterLogo from "shared/Svg/twiiterLogo";
import LinkdlnLogo from "shared/Svg/LinkdlnLogo";
import PhoneLogo from "shared/Svg/PhoneLogo";
import Dividerline from "shared/Svg/divider";
import MessageIcon from "shared/Svg/MessageIcon";
import DopahiyaLogo from "shared/Svg/DopahiyaLogo";
import CompareLogo from "shared/Svg/compareLogo";
import Starlogo from "shared/Svg/starlogo";
import CartIcon from "shared/Svg/cartIcon";
import { Link } from "react-router-dom";
import NotificationIcon from "shared/Svg/NotificationIcon";
import UserDropdown from "./UserDropdown";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import SearchProductModal from "./SearchProductModal";
import LoginModal from "components/model/LoginModal";
import ChangeBrand from "./ChangeBrand";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const location = useLocation();
  const token: any = useSelector(
    (state: RootState) => state.ContainerData.token
  );

  const renderContentSecond = () => {
    return (
      <div className="h-20 flex items-center ">
        <div className="flex items-center lg:hidden lg:flex-1">
          <MenuBar />
        </div>

        <Link to="/">
          <div className="lg:flex-1 flex items-center lg:mt-5 ">
            <DopahiyaLogo />
            <span className="mx-4 font-bold text-xl"> DO PAIYA PARTS </span>
          </div>
        </Link>
        <div className=" lg:flex items-end ml-auto mt-5 mb-3">
          <SearchProductModal />
        </div>
      </div>
    );
  };
  const renderContentThird = () => {
    return (
      <div className="hidden lg:flex  h-12  justify-between">
        <div className="flex-[2] hidden lg:flex justify-start ">
          <Navigation />
        </div>

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100 gap-3">
          {location.pathname === "/compare" ? (
            <>
              <Link to="/compare">
                <div className="">
                  <div className="mx-8">
                    <CompareLogo />
                  </div>
                  <p className="text-red-500 font-medium "> COMPARE </p>
                </div>
              </Link>
            </>
          ) : (
            <>
              {" "}
              <Link to="/compare">
                <div className="">
                  <div className="mx-8">
                    <CompareLogo />
                  </div>
                  <p className="text-[#1071FF] font-medium "> COMPARE </p>
                </div>
              </Link>
            </>
          )}

          {location.pathname === "/favorites" ? (
            <>
              {" "}
              <Link to="/favorites">
                <div className="">
                  <div className="mx-8 mt-1">
                    <Starlogo />
                  </div>
                  <p className="text-red-500 font-medium "> FAVORITES </p>
                </div>
              </Link>
            </>
          ) : (
            <>
              {" "}
              <Link to="/favorites">
                <div className="">
                  <div className="mx-8 mt-1">
                    <Starlogo />
                  </div>
                  <p className="text-[#1071FF] font-medium "> FAVORITES </p>
                </div>
              </Link>
            </>
          )}

          {location.pathname === "/cart" ? (
            <>
              {" "}
              <Link to="/cart">
                <div className="">
                  <div className="mx-3 mt-1">
                    <CartIcon />
                  </div>
                  <p className="text-red-500 font-medium "> CART </p>
                </div>{" "}
              </Link>
            </>
          ) : (
            <>
              {" "}
              <Link to="/cart">
                <div className="">
                  <div className="mx-3 mt-1">
                    <CartIcon />
                  </div>
                  <p className="text-[#1071FF] font-medium "> CART </p>
                </div>{" "}
              </Link>
            </>
          )}
        </div>
      </div>
    );
  };
  const renderFirstContent = () => {
    return (
      <div className=" lg:flex h-10  justify-between">
        <div className="hidden lg:flex lg:flex-1 items-start mt-2 gap-3">
          <FaceBookLogo />
          <InstagramLogo />
          <TwiiterLogo />
          <LinkdlnLogo />
        </div>

        <div className="mt-3 ">
          <div className="flex gap-2 items-center justify-between">
            {token && (
              <div>
                <UserDropdown />
              </div>
            )}

            <ChangeBrand />
            <div className=" hidden lg:flex gap-2 items-center">
              <Dividerline />
              <PhoneLogo />
              <span className="font-semibold	"> +91 987654321</span>
              <Dividerline />
            </div>
            {!token && (
              <>
                <div className="hidden lg:flex items-center gap-2">
                  <MessageIcon />
                  <span className="font-semibold	"> Messages</span>
                  <Dividerline />
                </div>
                <Link to="/signup">
                  <button className="bg-[#1071FF] hover:bg-blue-700 text-white font-normal  py-1 px-4 rounded-full text-sm">
                    Signup
                  </button>
                </Link>
              </>
            )}

            {token && (
              <div className="flex items-center gap-2">
                <NotificationIcon />
                <span className="font-semibold	"> Notification</span>
                <Dividerline />
                <div>
                  <UserDropdown />
                </div>
              </div>
            )}
            <LoginModal />
          </div>
        </div>
      </div>
    );
  };

  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    setShouldHide(
      location.pathname === "/signup" ||
        location.pathname.includes("/forgot-password") ||
        location.pathname === "/checkout-address"
    );
  }, [location.pathname]);

  return (
    <div
      className={`nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700 ${
        shouldHide ? "hidden" : ""
      }`}
    >
      <div className="container ">
        {renderFirstContent()}
        {renderContentSecond()}
        {renderContentThird()}
      </div>
    </div>
  );
};

export default MainNav2Logged;
