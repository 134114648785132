import React from "react";

function ShippingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g clipPath="url(#clip0_1128_40968)">
        <path
          fill="#151515"
          d="M20.5 8h-3V4h-14c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM20 9.5l1.96 2.5H17.5V9.5H20zM6.5 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H3.5V6h12v9H8.72zm9.78 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1128_40968">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShippingIcon;