import DopahiyaLogo from "shared/Svg/DopahiyaLogo";
import { useForm, Controller } from "react-hook-form";

const PageOrderTracking = () => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data, "OrderTracking Page");
  };
  return (
    <>
      <div className="container py-5">
        <div className="flex justify-center gap-5">
          <DopahiyaLogo />
          <h1 className="font-bold text-xl">DO PAIYA PARTS</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 flex justify-center">
            <div className="order-tracking-card p-5">
              <div>
                <label className="font-semibold 	"> Email/ Phone </label> <br />
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Email/ Phone is required" }} // Add required rule here
                  render={({ field }) => (
                    <input
                      type="text"
                      className={`mt-2 rounded-lg w-full ${
                        errors.name ? "border-red-500" : "" // Apply red border if there is an error
                      }`}
                      {...field}
                    />
                  )}
                />
                <br></br>
                {errors.name && typeof errors.name?.message === "string" && (
                  <span className="text-red-500">{errors.name?.message}</span>
                )}
              </div>
              <div className="mt-5">
                <label className="font-semibold 	"> Order </label> <br />
                <Controller
                  name="Order"
                  control={control}
                  rules={{
                    required:
                      "Please provide the order number of the order to be tracked.",
                  }} // Add required rule here
                  render={({ field }) => (
                    <input
                      type="text"
                      className={`mt-2 rounded-lg w-full ${
                        errors.name ? "border-red-500" : "" // Apply red border if there is an error
                      }`}
                      {...field}
                    />
                  )}
                />
                <br></br>
                {errors.Order && typeof errors.Order?.message === "string" && (
                  <span className="text-red-500">{errors.Order?.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center ">
            <button
              className="bg-black text-white rounded-full p-3"
              type="submit"
            >
              Fetch Details
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PageOrderTracking;
