import React from "react";

function CreditCard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g clipPath="url(#clip0_1128_40957)">
        <path
          fill="#151515"
          d="M20.5 4h-16c-1.11 0-1.99.89-1.99 2L2.5 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14h-16v-6h16v6zm0-10h-16V6h16v2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1128_40957">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CreditCard;