// import React from "react";

function Location() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g fill="#151515" clipPath="url(#clip0_1128_40962)">
        <path d="M12.5 2c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm-5 7c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88-2.08-2.67-5-7.03-5-9.88z"></path>
        <path d="M12.5 11.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1128_40962">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Location;