import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { SetLocalStorage } from "containers/store";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SetLocalStorage());
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Do Paiya</title>
        <meta name="description" content="Do-pahiya Parts" />
      </Helmet>{" "}
      {/* MAIN APP */}
      <div className=" bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
