import React from "react";

interface Props {}

function LiveChatIcon(props: Props) {
  const {} = props;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        fill="none"
        viewBox="0 0 60 60"
      >
        <path
          fill="red"
          fillRule="evenodd"
          d="M42.48 46.034a.406.406 0 01-.286-.107l-7.177-5.142c-9.51 3.515-19.104-3.493-19.104-13.177 0-7.749 6.32-14.069 14.07-14.069 7.784 0 14.068 6.32 14.068 14.07 0 2.927-.893 5.748-2.57 8.105l1.499 9.713c.05.315-.26.607-.5.607zm-7.034-6.213l6.356 4.57-1.322-8.748c0-.215.108-.357.179-.429a13.032 13.032 0 002.428-7.606c0-7.177-5.856-13.033-13.07-13.033-7.177 0-13.033 5.856-13.033 13.033 0 7.213 5.856 13.07 13.034 13.07a12.88 12.88 0 004.749-.893.615.615 0 01.678.036z"
          clipRule="evenodd"
        ></path>
        <path
          fill="red"
          fillRule="evenodd"
          d="M48.8 28.107a.488.488 0 01-.5-.5c0-10.07-8.212-18.282-18.318-18.282-10.07 0-18.282 8.213-18.282 18.282 0 .663-1 .663-1 0 0-10.64 8.677-19.318 19.318-19.318 10.641 0 19.318 8.677 19.318 19.318-.035.286-.25.5-.535.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="red"
          fillRule="evenodd"
          d="M48.8 40.391h-2.606a.488.488 0 01-.5-.5V27.607c0-.285.214-.5.5-.5h2.607a3.147 3.147 0 013.142 3.143v7.034c0 1.679-1.428 3.107-3.142 3.107zm-2.106-1.036H48.8a2.088 2.088 0 002.106-2.106v-7a2.088 2.088 0 00-2.106-2.106h-2.107v11.212zM13.806 40.391H11.2a3.147 3.147 0 01-3.143-3.142v-7a3.147 3.147 0 013.143-3.142h2.606c.286 0 .5.215.5.5v12.248c.036.286-.214.536-.5.536zM11.2 28.107a2.088 2.088 0 00-2.107 2.107v7.035c0 1.178.928 2.106 2.107 2.106h2.106V28.107H11.2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="red"
          fillRule="evenodd"
          d="M26.268 49.212h-.107a19.287 19.287 0 01-12.783-9.07c-.353-.607.556-1.114.892-.536 2.643 4.463 7.07 7.606 12.106 8.606.63.14.468 1-.108 1z"
          clipRule="evenodd"
        ></path>
        <path
          fill="red"
          fillRule="evenodd"
          d="M31.625 51.71H28.34a2.621 2.621 0 01-2.607-2.607c0-1.464 1.178-2.642 2.607-2.642h3.285c1.428 0 2.606 1.178 2.606 2.642.036 1.429-1.142 2.607-2.606 2.607zm-3.285-4.214a1.6 1.6 0 00-1.607 1.607 1.6 1.6 0 001.607 1.607h3.285a1.6 1.6 0 001.607-1.607 1.6 1.6 0 00-1.607-1.607H28.34zM23.09 30.142a2.88 2.88 0 01-2.892-2.892 2.88 2.88 0 012.892-2.893 2.903 2.903 0 012.893 2.893 2.88 2.88 0 01-2.893 2.892zm0-4.749a1.905 1.905 0 00-1.892 1.892c0 1.036.857 1.857 1.892 1.857a1.844 1.844 0 001.857-1.857c0-1.07-.821-1.892-1.857-1.892zM29.982 30.142a2.903 2.903 0 01-2.892-2.892 2.88 2.88 0 012.892-2.893 2.88 2.88 0 012.892 2.893 2.88 2.88 0 01-2.892 2.892zm0-4.749c-1.035 0-1.857.821-1.857 1.892 0 1.036.822 1.857 1.857 1.857 1.036 0 1.893-.82 1.893-1.857a1.905 1.905 0 00-1.893-1.892zM36.91 30.142a2.88 2.88 0 01-2.893-2.892 2.88 2.88 0 012.893-2.893 2.903 2.903 0 012.892 2.893c-.036 1.607-1.321 2.892-2.892 2.892zm0-4.749a1.905 1.905 0 00-1.893 1.892c0 1.036.857 1.857 1.893 1.857a1.844 1.844 0 001.857-1.857c0-1.07-.822-1.892-1.857-1.892z"
          clipRule="evenodd"
        ></path>
      </svg>
    </>
  );
}

export default LiveChatIcon;
