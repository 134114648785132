import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import React from "react";
import { useForm, Controller } from "react-hook-form";

interface Props {}

function PageExchangeReturn(props: Props) {
  const {} = props;
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    console.log(data, "OrderTracking Page");
  };
  return (
    <>
      <div className="container py-5">
        <Breadcrumb
          item1="Home"
          item1link="/"
          item2="How To Buy"
          item3={"Return and Exchange"}
        />
        <div className="py-5">
          <h1 className="text-center text-2xl font-medium">
            Returns and Exchange
          </h1>
          <p className="text-center py-2 font-normal">
            Enter your 5 digit order number and phone or email to <br></br> find
            your order
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" flex justify-center">
              <div className="">
                <div>
                  <Controller
                    name="Order"
                    control={control}
                    rules={{
                      required:
                        "Please provide the order number of the order to be tracked.",
                    }} // Add required rule here
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Order Number"
                        className={`mt-2  lg:w-72 md:w-72 sm:w-72  ${
                          errors.name ? "border-red-500" : "" // Apply red border if there is an error
                        }`}
                        {...field}
                      />
                    )}
                  />
                  <br></br>
                  {errors.Order &&
                    typeof errors.Order?.message === "string" && (
                      <div className=" lg:w-72 md:w-72 sm:w-72">
                        <span className="text-red-500 lg:w-72 md:w-72 sm:w-72 ">
                          {errors.Order?.message}
                        </span>
                      </div>
                    )}
                </div>
                <div className="mt-5">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Email/ Phone is required" }} // Add required rule here
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Phone or email"
                        className={`mt-2  lg:w-72 md:w-72 sm:w-72 ${
                          errors.name ? "border-red-500" : "" // Apply red border if there is an error
                        }`}
                        {...field}
                      />
                    )}
                  />
                  <br></br>
                  {errors.name && typeof errors.name?.message === "string" && (
                    <div className=" lg:w-72 md:w-72 sm:w-72">
                      <span className="text-red-500 ">
                        {errors.name?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-5 ">
              <button
                className="bg-black text-white rounded-full p-3 lg:w-72 md:w-72 sm:w-72"
                type="submit"
              >
                Find your Order
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PageExchangeReturn;
