function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      fill="none"
      viewBox="0 0 16 11"
    >
      <path
        fill="#0C0C0C"
        d="M8 10.333l-8-8L1.88.453 8 6.56 14.12.453 16 2.333l-8 8z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;