import RoundOne from "shared/Svg/Roundone";

const AddressCard = () => {
  return (
    <>
      <div className="mt-4">
        <div className="flex">
          <RoundOne number={1} />
          <h1 className="text-xl font-bold mx-2 ">Billing address </h1>
        </div>

        <div className="bg-[#E6EFFB] w-96 p-5 rounded-lg mt-5">
          <div className="mt-2 mb-2">
            <span> Company Name:</span>{" "}
            <span className="font-medium "> JKT Company</span> <br />
          </div>
          <div className="mt-2 mb-2">
            <span> GST NO:</span>{" "}
            <span className="font-medium"> 22AAAA00000A15</span> <br />
          </div>
          <div className="mt-2 mb-2">
            <span>Address:</span>{" "}
            <span className="font-medium">
              {" "}
              177 Kent St Brooklyn, N 11222, India
            </span>{" "}
            <br />
          </div>
          <div className="mt-2 mb-2">
            <span>Phone:</span>{" "}
            <span className="font-medium"> +91 987654321</span> <br />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddressCard;
