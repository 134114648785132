const ShippingAddressForm =() => {
    return (<>
    <h1 className="font-bold"> Add New Shipping Address</h1>
    
    <div className="flex">
              <div className=" items-center">
                <label className="font-semibold	"> Name </label> <br />
                <input
                  type="text"
                  className=" border rounded-md border-gray-500 focus:outline-none focus:border-0 "
                />
              </div>
              <div className=" mx-2 items-center">
                <label className="font-semibold	">Surname </label> <br />
                <input
                  type="text"
                  className="border border-gray-500 focus:outline-none focus:border-0 "
                />
              </div>
            </div>
    </>)
}
export default ShippingAddressForm