import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { PRODUCTS } from "data/data";
import LikeButtonNew from "./LikeButtonNew";
import TrashProductIcon from "shared/Svg/TrashProducticon";

export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const ProductCompareCard: FC<ProductCardProps> = ({
  className = "",
  data = [],
  isLiked,
}) => {
  const { name, status, image, id } = data;
  console.log(data, "data");

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent border rounded-lg   ${className}`}
        data-nc-id="ProductCard"
        key={id}
      >
        <div className="cursor-pointer flex justify-center bg-[#F6F8FB] py-2">
          <TrashProductIcon />
        </div>
        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
            src={image}
            className="object-cover w-full h-full drop-shadow-xl"
          />

          <LikeButtonNew
            liked={isLiked}
            className="absolute top-3 right-3 z-10"
          />
        </div>
        <div className="bg-[#F6F8FB]">
          <div className="p-5 ">
            <p className="text-center font-medium text-base">
              Heavy Duty Self One Way Clutch Bearing Kit{" "}
            </p>
          </div>
          <div className="p-5 ">
            <p className="text-center font-normal text-base">₹ 2,199</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">E14</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">Candle</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">4W</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">450 lm</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">warm light</p>
          </div>
          <div className="p-5 border">
            <p className=" font-normal text-sm text-center">Value</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCompareCard;
