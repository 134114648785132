// import React from "react";

function InstockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="28"
      fill="none"
      viewBox="0 0 117 28"
    >
      <rect
        width="87"
        height="27"
        x="0.5"
        y="0.5"
        fill="#fff"
        stroke="#F6F8FB"
        rx="3.5"
      ></rect>
      <path
        fill="#00893A"
        d="M30.632 18H29.4V8.2h1.232V18zm3.837 0h-1.176v-7.154h1.022l.098 1.12h.056c.434-.826 1.162-1.246 2.212-1.246 1.568 0 2.506.994 2.506 2.632V18h-1.176v-4.466c0-1.176-.546-1.764-1.652-1.764-1.19 0-1.89.84-1.89 2.282V18zm13.659.126c-1.722 0-2.884-.938-2.926-2.492h1.176c0 .938.686 1.442 1.75 1.442 1.092 0 1.638-.336 1.638-1.022 0-.518-.364-.868-1.092-1.022l-1.428-.266c-1.176-.238-1.82-.952-1.82-2.002 0-1.19 1.036-2.044 2.604-2.044 1.624 0 2.758.91 2.758 2.31H49.64c-.042-.882-.672-1.316-1.61-1.316-.896 0-1.47.378-1.47 1.05 0 .546.42.882 1.246 1.008l1.12.21c1.4.322 2.03.882 2.03 2.072 0 .63-.266 1.134-.812 1.512-.546.378-1.218.56-2.016.56zM56.356 18h-1.064c-1.358 0-1.89-.672-1.89-1.918V11.84h-1.19v-.994h1.218V8.928h1.148v1.918h1.75v.994h-1.75v4.144c0 .686.28.98.938.98h.84V18zm4.562.126c-1.078 0-1.876-.336-2.422-1.022-.546-.686-.812-1.582-.812-2.674 0-1.092.28-1.988.826-2.674.546-.686 1.358-1.036 2.408-1.036 1.05 0 1.862.35 2.408 1.036.546.686.826 1.582.826 2.674 0 1.092-.28 1.988-.826 2.674-.546.686-1.344 1.022-2.408 1.022zm0-1.05c1.4 0 2.044-1.022 2.044-2.646 0-1.624-.644-2.66-2.044-2.66-1.386 0-2.044 1.036-2.044 2.66s.658 2.646 2.044 2.646zm7.876 1.05c-2.114 0-3.248-1.4-3.248-3.696 0-2.282 1.148-3.71 3.234-3.71 1.834 0 2.828 1.05 2.94 2.576h-1.176c-.07-.952-.742-1.526-1.764-1.526-1.4 0-2.044 1.078-2.044 2.66 0 1.61.644 2.66 2.044 2.66 1.064 0 1.694-.546 1.792-1.498h1.176c-.154 1.568-1.148 2.534-2.954 2.534zM73.707 18V7.64h1.176V18h-1.176zm5.586-7.014l-2.898 2.996 2.982 3.878V18H78.02l-3.136-4.018 3.024-3.136h1.386v.14z"
      ></path>
      <g clipPath="url(#clip0_895_39214)">
        <path
          fill="#00893A"
          d="M16 7.333A6.67 6.67 0 009.333 14 6.67 6.67 0 0016 20.667 6.669 6.669 0 0022.667 14 6.67 6.67 0 0016 7.333zm0 12A5.34 5.34 0 0110.667 14 5.34 5.34 0 0116 8.667 5.34 5.34 0 0121.333 14 5.34 5.34 0 0116 19.334zm3.06-8.28l-4.393 4.394-1.727-1.72-.94.94 2.667 2.667L20 12l-.94-.947z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_895_39214">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(8 6)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstockIcon;