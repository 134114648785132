import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "containers/store";

import XIcon from "shared/Svg/x";
import ForgetPasswordScreen from "./ForgetPassword";
import { useForm, SubmitHandler } from "react-hook-form";
import { RootState } from "redux/store";
import Swal from "sweetalert2";
interface IFormInput {
  email: string;
  password: string;
}
export default function LoginModal() {
  const [showModal, setShowModal] = React.useState(false);
  const token: any = useSelector(
    (state: RootState) => state.ContainerData.token
  );

  const dispatch = useDispatch();

  const [ModelShowName, setModelShowName] = useState("Login");
  // console.log(ModelShowName, ',Mo')
  const handelModelAction = () => {
    setShowModal(false);
    setModelShowName("Login");
  };

  const handlePassback = (value: any) => {
    // Do something with the received value in the parent component
    setModelShowName(value);
  };
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(Login(data)).then((data: any) => {
      if (data.payload?.success) {
        setShowModal(false);
        window.location.reload();
      } else {
        Swal.fire({
          title: "Error",
          text: data.payload?.message,
          icon: "error",
        });
      }
    });
  };
  const { register, handleSubmit } = useForm<IFormInput>();
  return (
    <>
      {!token && (
        <button
          className="bg-[#1071FF] hover:bg-blue-700 text-white font-normal  py-1 px-4  text-sm rounded-full"
          onClick={() => setShowModal(true)}
        >
          Login
        </button>
      )}
      {showModal ? (
        <>
          <div className="  justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative my-8 mx-auto max-w-3xl  login-modal ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none   ">
                <div
                  className="ml-auto m-5 cursor-pointer"
                  onClick={handelModelAction}
                >
                  <XIcon />
                </div>
                {/* Login Model */}
                {ModelShowName === "Login" && (
                  <div className="LoginModel">
                    <div>
                      <p className="text-center font-bold text-2xl">
                        {" "}
                        Welcome back! Log in{" "}
                      </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {/*header*/}
                      <div className=" mx-10">
                        <div className=" flex items-start justify-start mt-5 ">
                          <label>Login/e-mail</label>
                        </div>
                        <input
                          type="text"
                          {...register("email", { required: true })}
                          className="  w-full border-[#EAECEE] mt-2 rounded-md px-4 py-2"
                          placeholder=""
                        />

                        <div className=" flex items-start justify-start mt-5 ">
                          <label>Password</label>
                        </div>
                        <input
                          type="password"
                          {...register("password", { required: true })}
                          className=" w-full border-[#EAECEE] mt-2 rounded-md px-4 py-2"
                          placeholder=""
                        />
                        <div className="flex mt-2">
                          <p
                            className="font-medium ml-auto cursor-pointer"
                            onClick={() => {
                              setModelShowName("ForgetModel");
                            }}
                          >
                            Forgot password?{" "}
                          </p>
                        </div>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <button
                          className="rounded-full w-full p-2 bg-[#151515] text-white"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                      {/*footer*/}
                      <div>
                        <p className="text-black mx-5 mb-5 text-center">
                          {" "}
                          You do not have an account?{" "}
                          <span className="font-bold"> Register</span>{" "}
                        </p>
                      </div>
                    </form>
                  </div>
                )}

                {/* Login Model Close */}
                {ModelShowName === "ForgetModel" && (
                  <ForgetPasswordScreen
                    onPassback={handlePassback}
                    setShowModal={setShowModal}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
