import React from "react";

interface Props {}

function RightArrowButtonIcon(props: Props) {
  const {} = props;

  return (
    <>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_1387_12733)">
          <path
            fill="#1071FF"
            d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1387_12733">
            <path fill="#fff" d="M0 0H24V24H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default RightArrowButtonIcon;
