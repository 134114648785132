import  { FC, useEffect, useId, useRef } from "react";
// import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
// import ProductCard from "./ProductCard";
import { Product, PRODUCTS } from "data/data";
// import RightArrow from "shared/svg/RightArrow";
import ProductCardNew from "./ProductCardNew";
import p1 from "../../src/images/productImg/p1.png"
import p2 from "../../src/images/productImg/p2.png"
import p3 from "../../src/images/productImg/p3.png"
import p4 from "../../src/images/productImg/p4.png"

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const SectionProductCardRecently: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
  data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      // image:'https://images.cmsnl.com/img/products/retainerrr-sprocket-drum_big6473315D00-01_a7a2.jpg',
      image:p1,
   },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image:p2,
   },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image:p3,
   },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image:p4,
   },
  ];

  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      {/* <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}> */}
        {/* <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          // rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `New Arrivals`}
        </Heading> */}
      <div className="flex">
  <h1 className="text-2xl font-normal">Recently Viewed</h1>
  {/* <p className="ml-auto flex">Show all recommended products <span className="mx-2 mt-1" > <RightArrow/> </span> </p> */}
</div>
<div className=" grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
        {/* <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides"> */}
            {ProductData.map((item, index) => (
              <li key={index} style={{listStyle:'none'}}>
               {/* <li key={index} className={`glide__slide ${itemClassName}`}> */}
             
                {/* <ProductCard data={item} /> */}
                <ProductCardNew data={item} />
              </li>
            
            ))}
          {/* </ul>
        </div> */}
      {/* </div> */}
    </div>
</div>

  );
};

export default SectionProductCardRecently;
