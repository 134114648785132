import SectionProductCardNew from "components/SectionProductCardNew";

import CategoriesCard from "components/Categoriescard";
import SectionProductCardRecently from "components/SectionProductCardRecently";
import SectionAdvertisement from "./SectionAdvertisment";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 my-10 lg:space-y-32 lg:my-10">
        <SectionProductCardNew
          heading="Best Sellers"
          subHeading="Best selling of the month"
        />
        <SectionAdvertisement />
        <CategoriesCard />
        <SectionProductCardRecently />
      </div>
    </div>
  );
}

export default PageHome;
