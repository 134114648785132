// import React from "react";

function CompareLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      {location.pathname === "/compare" ? (
        <>
          {" "}
          <g clipPath="url(#clip0_353_77030)">
            <path
              fill="#EF0606"
              d="M6.667 2H3.333C2.6 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h3.334v1.333H8V.667H6.667V2zm0 10H3.333l3.334-4v4zm6-10H9.333v1.333h3.334V12L9.333 8v6h3.334C13.4 14 14 13.4 14 12.666V3.333C14 2.6 13.4 2 12.667 2z"
            ></path>
          </g>
        </>
      ) : (
        <>
          {" "}
          <g clipPath="url(#clip0_353_77030)">
            <path
              fill="#1071FF"
              d="M6.667 2H3.333C2.6 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h3.334v1.333H8V.667H6.667V2zm0 10H3.333l3.334-4v4zm6-10H9.333v1.333h3.334V12L9.333 8v6h3.334C13.4 14 14 13.4 14 12.666V3.333C14 2.6 13.4 2 12.667 2z"
            ></path>
          </g>{" "}
        </>
      )}

      <defs>
        <clipPath id="clip0_353_77030">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompareLogo;
