
function Notification() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.0175 1.42578C5.25914 1.42578 3.01747 3.66745 3.01747 6.42578V8.83411C3.01747 9.34244 2.80081 10.1174 2.54247 10.5508L1.58414 12.1424C0.992472 13.1258 1.40081 14.2174 2.48414 14.5841C6.07581 15.7841 9.95083 15.7841 13.5425 14.5841C14.5508 14.2508 14.9925 13.0591 14.4425 12.1424L13.4842 10.5508C13.2342 10.1174 13.0175 9.34244 13.0175 8.83411V6.42578C13.0175 3.67578 10.7675 1.42578 8.0175 1.42578Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>

  );
}

export default Notification;