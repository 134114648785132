

function ProductCompare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
    >
      <rect
        width="43"
        height="43"
        x="0.5"
        y="0.5"
        stroke="#151515"
        rx="7.5"
      ></rect>
      <g clipPath="url(#clip0_1140_40444)">
        <path
          fill="#151515"
          d="M20 13h-5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v2h2V11h-2v2zm0 15h-5l5-6v6zm9-15h-5v2h5v13l-5-6v9h5c1.1 0 2-.9 2-2V15c0-1.1-.9-2-2-2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1140_40444">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(10 10)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProductCompare;