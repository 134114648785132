// import React from "react";

function DownLoadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1169_36067)">
        <path
          fill="#151515"
          d="M19 9.5h-4v-6H9v6H5l7 7 7-7zm-8 2v-6h2v6h1.17L12 13.67 9.83 11.5H11zm-6 7h14v2H5v-2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1169_36067">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownLoadIcon;