import { Link } from "react-router-dom"

const CheckOutHeader = () => {
    return (<>

  <div className="bg-[#E6EFFB] h-20 flex flex-col justify-center">
  <Link to='/cart'>
  <h1 className="text-black mx-20 mt-auto mb-auto "> Go Back </h1>
  </Link>
  </div>
 

</>)}

export default CheckOutHeader