import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbProps {
  item1?: string;
  item1link?: string;
  item2?: string;
  item2link?: string;
  item3?: string;
  item3link?: string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  item1 = "",
  item1link = "",
  item2,
  item2link = "",
  item3,
  item3link = "",
}) => {
  return (
    <>
      <h4 className="text-[#1071FF] text-base">
        <Link to={item1link}>{item1}</Link>
        {item2 && (
          <>
            <span className="mr-2"> /</span>
            <Link to={item2link}>{item2}</Link>{" "}
          </>
        )}
        {item3 && (
          <>
            <span className="mr-2"> /</span>

            <Link to={item3link}>{item3}</Link>
          </>
        )}
      </h4>
    </>
  );
};

export default Breadcrumb;
