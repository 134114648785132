import { FC } from "react"

export interface RoundNoProps {
    number ?: any;
}



const RoundOne : FC <RoundNoProps> = ({number}) => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="15.5" fill="#151515" stroke="#151515"></circle>
      <text
        x="50%"
        y="50%"
        fill="#FFF"
        alignmentBaseline="middle"
        fontSize="16"
        textAnchor="middle"
      >
        {number}
      </text>
    </svg>
    </>)
}

export default RoundOne