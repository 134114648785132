import React from "react";

interface Props {}

function ContactUsCircle(props: Props) {
  const {} = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="209"
      fill="none"
      viewBox="0 0 208 209"
    >
      <circle cx="162.5" cy="160.5" r="134.5" fill="#1A1A1A"></circle>
      <circle cx="69" cy="69" r="69" fill="#484848" fillOpacity="0.5"></circle>
    </svg>
  );
}

export default ContactUsCircle;
