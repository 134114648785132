import { FC } from "react";
import { Link } from "react-router-dom";
import RightArrow from "shared/Svg/RightArrow";

export interface SectionHomePageHeadingProps {
  heading?: any;
  LastSubHeading?: any;
  navigateLink?: any;
}

const SectionHomePageHeading: FC<SectionHomePageHeadingProps> = ({
  heading,
  LastSubHeading,
  navigateLink,
}) => {
  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-sm lg:text-2xl font-medium">{heading}</h1>

        <Link to={navigateLink}>
          <p className=" flex     lg:ml-auto flex lg:text-base">
            <span className="hidden sm:inline">{LastSubHeading}</span>
            <span className=" mx-1    lg:hidden ">Show all</span>
            <span className="mx-1   lg:mx-2 mt-1">
              <RightArrow />
            </span>
          </p>
        </Link>
      </div>
    </>
  );
};

export default SectionHomePageHeading;
