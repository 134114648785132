import React from "react";

function ShareIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    fill="none"
    viewBox="0 0 44 44"
  >
    <rect
      width="43"
      height="43"
      x="0.5"
      y="0.5"
      stroke="#151515"
      rx="7.5"
    ></rect>
    <g clipPath="url(#clip0_1137_63548)">
      <path
        fill="#151515"
        d="M28 26.08c-.76 0-1.44.3-1.96.77l-7.13-4.15c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.05 4.11c-.54-.5-1.25-.81-2.04-.81-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92zM28 14c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-12 9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1137_63548">
        <path
          fill="#fff"
          d="M0 0H24V24H0z"
          transform="translate(10 10)"
        ></path>
      </clipPath>
    </defs>
  </svg>
  );
}

export default ShareIcon;