import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
// import { Product, PRODUCTS } from "data/data";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CartStarLogo from "shared/Svg/CartStarLogo";
import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";
import TrashIcon from "shared/Svg/TrashIcon";
import CreditCard from "shared/Svg/credit_card";
import Location from "shared/Svg/Location";
import InfoIcon from "shared/Svg/infoIcon";
import DownLoadIcon from "shared/Svg/downloadIcon";
import TrashProductIcon from "shared/Svg/TrashProducticon";

const CartPage = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      // image:'https://images.cmsnl.com/img/products/retainerrr-sprocket-drum_big6473315D00-01_a7a2.jpg',
      image: p1,
    },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 5,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 6,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 7,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 8,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
  ];

  const renderProduct = (item: any, index: any) => {
    const { image, price, name } = item;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative  sm:w-auto flex-shrink-0 overflow-hidden rounded-xl bg-white mr-5">
          <img src={image} alt={name} className=" h-36 w-36 object-cover" />
          <div className="absolute top-0 right-0">
            <CartStarLogo />
          </div>
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className=" w-full flex  flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h4 className="text-[#737B7D] text-sm mb-1">
                  Brand Name | Part No. 234565
                </h4>
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <h4 className="text-[#3DB62A]">
                      {" "}
                      Delivery date: April 4th
                    </h4>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <h6 className="text-xs font-normal">
                  {" "}
                  70.00 net <br />{" "}
                  <span className="ml-5 font-semibold text-base">₹150 </span>
                </h6>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-start justify-start  mt-5">
            <p className="text-start  text-sm lg:text-base mt-1">Item:</p>
            <span className="mx-2">
              <NcInputNumber />
            </span>
            <span className="mt-4 lg:ml-auto  cursor-pointer">
              <TrashIcon />
            </span>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm"></div>
        </div>
      </div>
    );
  };

  const renderCartGird = () => {
    return (
      <>
        <div className="grid grid-cols-12 gap-5 ">
          <div className=" col-span-12  lg:col-span-8 xl:col-span-8">
            <div className=" ">
              <div className="bg-[#F6F8FB] p-5">
                <h1 className="text-base font-medium mt-2">
                  Search by parts or part number
                </h1>
                <div className="sm:flex col-auto   lg:flex gap-0 mt-2">
                  <input
                    type="text"
                    className="w-full lg:w-96 h-10 mx-2 mt-2 border rounded-lg border-[#737B7D]"
                    placeholder="Enter product name or part no."
                  />
                  <select
                    id="dropdown"
                    className=" mt-2 border rounded-lg border-[#737B7D] mx-2 "
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <option value="">Quantity</option>
                    <option value="option1"> 1</option>
                    <option value="option2"> 2</option>
                    <option value="option3"> 3</option>
                  </select>
                  <button className="rounded-full bg-[#737B7D] text-sm  p-1 mt-1 w-48 text-white">
                    Add item
                  </button>
                </div>
              </div>

              <div className="  mt-10 p-5 rounded-lg bg-[#151515] flex ">
                <h2 className="text-white"> Cart </h2>
                <h2 className="text-white mx-5"> Items: 21 </h2>
                <p className="text-white ml-auto mx-2"> Save cart</p>
              </div>
              <div className="px-5  mt-10  divide-y divide-slate-200 dark:divide-slate-700 ">
                {ProductData.map(renderProduct)}
              </div>
              <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />
              {/* Promotion Section */}
              <div className="bg-[#F6F8FB] py-2">
                <div className="flex mt-5 mx-4 divide-y divide-slate-200/70">
                  <input
                    type="text"
                    className="rounded-lg"
                    placeholder="Enter promotion code"
                  />
                  <button className="bg-[#151515] text-white p-2 w-24 rounded-full mx-2">
                    Apply{" "}
                  </button>
                </div>
                <div className="flex mx-5 mt-5 ">
                  <DownLoadIcon />{" "}
                  <span className="mx-2 text-sm mt-1 font-medium">
                    {" "}
                    Download cart{" "}
                  </span>
                  <div className="ml-auto font-medium mt-2 text-sm">
                    Price net: <span className="ml-36">₹910.00</span>
                  </div>
                </div>
                <div className="flex mx-5 mt-2">
                  <TrashProductIcon />{" "}
                  <span className="mx-2 text-[#434447] text-sm mt-1">
                    {" "}
                    Remove cart{" "}
                  </span>
                  <div className="ml-auto ">
                    <span className="font-medium text-base">
                      {" "}
                      Total price (gross):{" "}
                    </span>{" "}
                    <span className="ml-10 font-medium">₹1154.00</span>
                  </div>
                </div>
              </div>

              {/* Promotion Section End */}
            </div>
          </div>
          <div className=" col-span-12 lg:col-span-4 xl:col-span-4">
            <div className="bg-[#F6F8FB] p-4 rounded-lg">
              <h3 className="text-lg font-semibold ">Summary</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span className="text-black">Price net:</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $249.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span className="text-black">Shipping cost:</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    <span className="mx-2"> from </span> $5.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span className="text-black">Discount:</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    - $24.90
                  </span>
                </div>
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Total price :</span>
                  <span>$276.00</span>
                </div>
              </div>

              <Link to="/checkout-address">
                <div className="flex justify-center mt-5">
                  <button className="text-white bg-[#151515] rounded-full w-full   p-3">
                    Go to checkout
                  </button>
                </div>
              </Link>
              <div className="flex justify-center mt-2">
                <button className="text-black bg-white rounded-full w-full p-3 border border-black">
                  Go back to shopping
                </button>
              </div>

              <div className="mt-5 mx-6">
                <div className="flex mb-2">
                  <CreditCard />
                  <span className="mx-2 font-normal"> Safety payment </span>
                </div>
                <div className="flex mb-2">
                  <Location />
                  <span className="mx-2">
                    {" "}
                    More than 5 000 stationary shop{" "}
                  </span>
                </div>
                <div className="flex">
                  <InfoIcon />

                  <span className="mx-2"> Free shipping and returns </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-5 ">
        <div className="mb-2">
          <Breadcrumb item1="Home " item1link="/" item2="Cart" />
        </div>

        {renderCartGird()}
      </div>
    </>
  );
};

export default CartPage;
