import React from "react";

function PrinterIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_1387_505)">
          <path
            fill="#434447"
            d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z"
          ></path>
          <path fill="#0C0C0C" d="M18 12.5a1 1 0 100-2 1 1 0 000 2z"></path>
        </g>
        <defs>
          <clipPath id="clip0_1387_505">
            <path fill="#fff" d="M0 0H24V24H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default PrinterIcon;
