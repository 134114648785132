
function ZoomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_1128_43042)">
        <path
          fill="#0C0C0C"
          d="M20.667 18.667h-1.054l-.373-.36a8.628 8.628 0 002.093-5.64A8.666 8.666 0 0012.667 4 8.666 8.666 0 004 12.667a8.666 8.666 0 008.667 8.666c2.146 0 4.12-.786 5.64-2.093l.36.373v1.054l6.666 6.653 1.987-1.987-6.653-6.666zm-8 0c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6zm.666-9.334H12V12H9.333v1.333H12V16h1.333v-2.667H16V12h-2.667V9.333z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1128_43042">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ZoomIcon;