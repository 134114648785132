import RoundOne from "shared/Svg/Roundone"

const ShippingAddressCard = () => {
    return (<>
<div className="mt-4 ">
<div className="flex">
            <RoundOne />
        <h1 className="text-xl font-bold mx-2 ">
        Shipping Address </h1>
        </div>

        <span className="text-sm">Choose from shipping method</span>
</div>

    </>)
}
export default ShippingAddressCard