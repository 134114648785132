import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom"
import axios from "axios";
import swl from 'sweetalert2'
import { useForm, Controller } from "react-hook-form";




const ResetPasswordModelScreen = () => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset

  } = useForm();

  const Navigate = useNavigate()
  const params = useParams();
  const token = params.token;
  const [email, setEmail] = useState('')
  const [ApiErrors, setApiErrors] = useState<string[]>([]);

  const backgroundStyle = {
    backgroundColor: '#15151514'
  }
  const boxStyle = {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 18px 0px #4B465C1A'
  }
  const baseUrl = process.env.REACT_APP_BASE_URL_ADMIN;

  const handleResetPassword = async (data: any) => {
    const obj = {
      email: email,
      password: data.password,
      token:token
    }
    await axios({
      method: 'POST',
      url: `${baseUrl}/reset-password`,
      headers: {
        'content-type': 'application/json',
      },
      data:obj
    }).then((res) => {
      setApiErrors([]);
      swl.fire({
        title: 'Success',
        text: 'Successfully password changed',
        icon: 'success'
      })
      Navigate('/')

    }).catch((error) => {
      setApiErrors([]);
      const tempData = error?.response.data.errors;
      Object.keys(tempData).map(i => {
        setApiErrors(prevData => [...prevData, tempData[i]]);
      })
    })
  }
  const getEmail = async () => {
    await axios(`${baseUrl}/email-by-token/${token}`)
      .then((res) => {
        setEmail(res.data.email)
      })
      .catch(() => {
        Navigate('/')
      })
  }

  useEffect(() => {
    getEmail()
  }, [])
  return (<>
    <div className="w-screen h-screen place-content-center flex items-center" style={backgroundStyle}>
      <div className="lg:w-2/6 md:w-1/2	 p-8 rounded-md	" style={boxStyle}>
        <div >
          <p className="font-medium text-xl">
            Reset password
          </p>
          <small>
            for {email}
          </small>
        </div>
        <div >
          <form onSubmit={handleSubmit(handleResetPassword)}>

            <div className="mt-3 ">
              <label>New password</label>
              <Controller
                name="password"
                control={control}
                rules={{ required: "password is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="password"
                    className={`w-full border-[#EAECEE] mt-2 rounded-md px-4 py-2 ${errors.password ? "border-red-500" : ""}`}
                    {...field}

                  />
                )}
              />
              {errors.password && typeof errors.password?.message === 'string' && (
                <span className="text-red-500">{errors.password?.message}</span>
              )}
            </div>
            <div className="mt-3">
              <label>Confirm new password</label>
              <Controller
                name="confirmpassword"
                control={control}
                rules={{
                  required: "confirm password is required",
                  validate: (value) =>
                    value === getValues("password") || "Passwords do not match",
                }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="password"
                    className={`w-full border-[#EAECEE] mt-2 rounded-md px-4 py-2 ${errors.confirmpassword ? "border-red-500" : ""}`}
                    {...field}
                  />
                )}
              />
              {errors.confirmpassword && typeof errors.confirmpassword?.message === 'string' && (
                <span className="text-red-500">{errors.confirmpassword?.message}</span>
              )}
            </div>
            {ApiErrors.length>0 && <div className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Danger</span>
              <div>
                <span className="font-medium">Ensure that these requirements are met:</span>
                <ul className="mt-1.5 list-disc list-inside">
                  {
                    ApiErrors.map((i, index) => {
                      return <li key={index}>{i}</li>;
                    })
                  }
                </ul>
              </div>
            </div>}
            <div className="relative  flex justify-center mt-4">
              <button
                className="rounded-md w-full p-2 bg-[#151515] text-white"
              >
                Reset password
              </button>
            </div>
            <div className="text-center mt-3">
              <a href='/'>
                {'< Back to login'}
              </a>
            </div>
          </form>
        </div>
      </div>

      {/*footer*/}
    </div>
  </>)
}
export default ResetPasswordModelScreen