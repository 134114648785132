const LinkdlnLogo =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#0A66C2"
        d="M19.102 19.102h-2.779V14.75c0-1.037-.018-2.373-1.445-2.373-1.447 0-1.669 1.13-1.669 2.296v4.429h-2.778v-8.949h2.667v1.224h.038a2.93 2.93 0 012.631-1.446c2.816 0 3.336 1.852 3.336 4.263l-.001 4.906v.002zM7.297 8.93a1.62 1.62 0 01-1.613-1.611 1.62 1.62 0 011.613-1.613c.884 0 1.61.728 1.612 1.613a1.62 1.62 0 01-1.612 1.61zm1.389 10.172H5.905v-8.949h2.78v8.949zM20.484 3.125H4.508a1.375 1.375 0 00-1.383 1.353v16.044a1.375 1.375 0 001.383 1.353h15.978a1.378 1.378 0 001.389-1.353V4.477a1.375 1.375 0 00-1.39-1.35v-.002z"
      ></path>
    </svg>
    
    </>)
}

export default LinkdlnLogo