import MainHeading from "components/Heading/MainHeading";
import LabelSlug from "components/Breadcrumb/Breadcrumb";
import SectionProductGird from "components/SectionProductGrid";
import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";

const ShowAllParts = () => {
  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      // image:'https://images.cmsnl.com/img/products/retainerrr-sprocket-drum_big6473315D00-01_a7a2.jpg',
      image: p1,
    },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 5,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 6,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 7,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 8,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
  ];
  return (
    <>
      <main className="container mt-5 lg:mt-11">
        <LabelSlug item1="Home " item1link="/" item2=" Show All Parts" />
        <div className="mt-2">
          <MainHeading MainHeading="All Parts" />
        </div>
        <SectionProductGird ProductData={ProductData} />
      </main>
    </>
  );
};
export default ShowAllParts;
