const FaceBookLogo =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g clipPath="url(#clip0_353_77058)">
        <path
          fill="#4460A0"
          fillRule="evenodd"
          d="M13.353 25H1.38A1.38 1.38 0 010 23.62V1.38C0 .618.618 0 1.38 0h22.24C24.382 0 25 .618 25 1.38v22.24A1.38 1.38 0 0123.62 25h-6.37v-9.681h3.25l.486-3.773H17.25v-2.41c0-1.092.303-1.836 1.87-1.836h1.997V3.924c-.345-.046-1.531-.15-2.911-.15-2.88 0-4.853 1.76-4.853 4.988v2.783h-3.258v3.773h3.258V25z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_353_77058">
          <path fill="#fff" d="M0 0H25V25H0z"></path>
        </clipPath>
      </defs>
    </svg> 
    
    </>)
}

export default FaceBookLogo