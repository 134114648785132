import React from "react";
import { useLocation } from "react-router-dom";

function Starlogo() {
  const location = useLocation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      fill="none"
      viewBox="0 0 14 13"
    >
      {location.pathname === "/favorites" ? (
        <>
          {" "}
          <path
            fill="#EF0606"
            d="M13.667 5.16l-4.794-.413L7 .333l-1.873 4.42-4.794.407 3.64 3.153L2.88 13 7 10.514 11.12 13l-1.087-4.687 3.634-3.153zM7 9.267L4.493 10.78l.667-2.853-2.213-1.92 2.92-.253L7 3.067 8.14 5.76l2.92.253-2.213 1.92.666 2.854L7 9.267z"
          ></path>{" "}
        </>
      ) : (
        <>
          {" "}
          <path
            fill="#1071FF"
            d="M13.667 5.16l-4.794-.413L7 .333l-1.873 4.42-4.794.407 3.64 3.153L2.88 13 7 10.514 11.12 13l-1.087-4.687 3.634-3.153zM7 9.267L4.493 10.78l.667-2.853-2.213-1.92 2.92-.253L7 3.067 8.14 5.76l2.92.253-2.213 1.92.666 2.854L7 9.267z"
          ></path>
        </>
      )}
    </svg>
  );
}

export default Starlogo;
