import React from "react";
import { useLocation } from "react-use";

function CartIcon() {
  const location = useLocation()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_353_77036)">
        { 
          location.pathname==='/cart' ? <>   <path

          fill="#EF0606"
          d="M10.367 8.667c.5 0 .94-.274 1.166-.687l2.387-4.327a.664.664 0 00-.58-.986H3.473l-.626-1.333H.667v1.333H2l2.4 5.06-.9 1.627c-.487.893.153 1.98 1.167 1.98h8V10h-8L5.4 8.667h4.967zM4.107 4h8.1l-1.84 3.333h-4.68L4.107 4zm.56 8c-.734 0-1.327.6-1.327 1.334 0 .733.593 1.333 1.327 1.333.733 0 1.333-.6 1.333-1.333C6 12.6 5.4 12 4.667 12zm6.666 0c-.733 0-1.326.6-1.326 1.334 0 .733.593 1.333 1.326 1.333.734 0 1.334-.6 1.334-1.333 0-.734-.6-1.334-1.334-1.334z"
        ></path> </>   :  <>   <path

        fill="#1071FF"
        d="M10.367 8.667c.5 0 .94-.274 1.166-.687l2.387-4.327a.664.664 0 00-.58-.986H3.473l-.626-1.333H.667v1.333H2l2.4 5.06-.9 1.627c-.487.893.153 1.98 1.167 1.98h8V10h-8L5.4 8.667h4.967zM4.107 4h8.1l-1.84 3.333h-4.68L4.107 4zm.56 8c-.734 0-1.327.6-1.327 1.334 0 .733.593 1.333 1.327 1.333.733 0 1.333-.6 1.333-1.333C6 12.6 5.4 12 4.667 12zm6.666 0c-.733 0-1.326.6-1.326 1.334 0 .733.593 1.333 1.326 1.333.734 0 1.334-.6 1.334-1.333 0-.734-.6-1.334-1.334-1.334z"
      ></path>   </>
        }
      
      </g>
      <defs>
        <clipPath id="clip0_353_77036">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CartIcon;