import  { FC, useEffect, useId, useRef } from "react";
// import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
// import ProductCard from "./ProductCard";
import { Product } from "data/data";
// import RightArrow from "shared/Svg/RightArrow";
// import ProductCardNew from "./ProductCardNew";
// import CategoriesCardNew from "./CategoriesCardNew";
import img1 from "../../src/images/categories/image (1).png";
import img2 from "../../src/images/categories/image (2).png";
import img3 from "../../src/images/categories/image (3).png";
import img4 from "../../src/images/categories/image (4).png";
import SectionHomePageHeading from "./SectionHomePageHeading";
import SectionCategoriesGird from "./SectionCategoriesGrid";
export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const CategoriesCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  // itemClassName = "",
  // headingFontClassName,
  // headingClassName,
  // heading,
  // subHeading = "REY backpacks & bags",
  // data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  // console.log(data, "data**");

  const CategoriesData = [
    {
      id: 1,
      name: "Brake and Clutch",
      image: img1,
    },
    {
      id: 2,
      name: "Engine and Electrical",
      image: img2,
    },
    {
      id: 3,
      name: "Bike Body parts",
      image: img3,
    },
    {
      id: 4,
      name: "Other Parts",
      image: img4,
    },
  ];

  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <SectionHomePageHeading
        heading="Recommended Categories"
        LastSubHeading="Show all recommended products"
        navigateLink="/show-all-recommended-category"
      />
      <SectionCategoriesGird CategoriesData={CategoriesData} />
    </div>
  );
};

export default CategoriesCard;
