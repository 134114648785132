

function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      fill="none"
      viewBox="0 0 16 11"
    >
      <path
        fill="#0C0C0C"
        d="M14.12 10.547L8 4.44l-6.12 6.107L0 8.667l8-8 8 8-1.88 1.88z"
      ></path>
    </svg>
  );
}

export default ArrowUpIcon;