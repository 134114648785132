const Dividerline =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="28"
      fill="none"
      viewBox="0 0 1 28"
    >
      <path fill="#434447" d="M0 0H1V28H0z"></path>
    </svg>
    </>)
}

export default Dividerline