import SignUpForm from "components/SignupPage/SignupForm";
import React, { FC } from "react";

import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";


export interface PageSignUpProps {
  className?: string;
}



const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <Link to="/">
          <div className=" flex items-center justify-center mt-5">
       
            <span className="mx-4 font-bold text-2xl"> DO PAIYA PARTS </span>
          </div>
        </Link>

        <p className=" text-[#1071FF] text-2xl  text-center font-bold mt-2 ">
          Registration
        </p>

     <SignUpForm/>
      
      </div>
    </div>
  );
};

export default PageSignUp;
