import Img1 from "../../images/image 28.png";

const SectionAdvertisement = () => {
  return (
    <>
      <div className="bg-black w-full h-36   flex items-center justify-center">
        <img src={Img1} alt="img" className="hidden sm:block lg:object-cover" />

        <p className="text-white text-sm mx-1 lg:text-7xl justify-center ">
          {" "}
          -20%
        </p>
        <p className="text-white text-sm lg:text-4xl text-center mx-4">
          {" "}
          Discount on TVS parts{" "}
        </p>
        <div className="lg:ml-auto">
          <button className="  bg-[#FFE500] text-black text-base   rounded-full p-2 mx-10 w-28 ">
            {" "}
            Check offer
          </button>
        </div>
      </div>
    </>
  );
};

export default SectionAdvertisement;
