import React from "react";

interface Props {}

function InstagramLogoContact(props: Props) {
  const {} = props;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="none"
        viewBox="0 0 30 30"
      >
        <circle cx="15" cy="15" r="15" fill="#fff"></circle>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.408 7.045C13.208 7.008 13.463 7 15.5 7s2.292.009 3.091.045c.8.036 1.345.164 1.822.348.5.19.953.485 1.328.866.382.375.677.827.865 1.328.185.477.312 1.023.349 1.82.037.801.045 1.056.045 3.093s-.009 2.292-.045 3.092c-.036.798-.164 1.343-.35 1.82a3.68 3.68 0 01-.864 1.33 3.678 3.678 0 01-1.328.864c-.477.185-1.023.312-1.82.349-.8.037-1.056.045-3.093.045s-2.292-.009-3.092-.045c-.798-.036-1.343-.164-1.82-.35a3.68 3.68 0 01-1.33-.864 3.677 3.677 0 01-.865-1.328c-.184-.477-.311-1.023-.348-1.82C8.008 16.792 8 16.537 8 14.5s.009-2.292.045-3.091c.036-.8.164-1.345.348-1.822.19-.5.485-.954.866-1.328a3.676 3.676 0 011.328-.866c.477-.184 1.023-.311 1.82-.348h.001zm6.123 1.35c-.791-.036-1.028-.044-3.031-.044-2.002 0-2.24.008-3.03.044-.732.033-1.13.155-1.394.258-.35.137-.6.298-.862.56-.249.243-.44.538-.56.863-.104.265-.226.662-.259 1.393-.036.791-.044 1.029-.044 3.031 0 2.003.008 2.24.044 3.03.033.732.155 1.13.258 1.394.12.325.312.62.56.862.243.249.538.44.863.56.265.104.662.226 1.393.259.791.036 1.028.044 3.031.044s2.24-.008 3.03-.044c.732-.033 1.13-.155 1.394-.258.35-.137.6-.298.862-.56.249-.243.44-.538.56-.863.104-.265.226-.662.259-1.393.036-.791.044-1.028.044-3.031 0-2.002-.008-2.24-.044-3.03-.033-.732-.155-1.13-.258-1.394a2.33 2.33 0 00-.56-.862 2.327 2.327 0 00-.863-.56c-.265-.104-.662-.226-1.393-.259zm-3.989 8.417a2.502 2.502 0 10.713-4.802 2.501 2.501 0 00-.713 4.802zm-1.768-5.038a3.856 3.856 0 115.453 5.453 3.856 3.856 0 01-5.453-5.453zm7.436-.555a.912.912 0 10-1.251-1.326.912.912 0 001.251 1.326z"
          clipRule="evenodd"
        ></path>
      </svg>
    </>
  );
}

export default InstagramLogoContact;
