import  { FC } from "react";
// import React, { FC, useEffect, useId, useRef } from "react";
// import ProductCardNew from "./ProductCardNew";
// import RightArrow from "shared/Svg/RightArrow";
import CategoriesCardNew from "./CategoriesCardNew";

export interface SectionProductGridProps {
  

  CategoriesData:any;
 
}

const SectionCategoriesGrid: FC<SectionProductGridProps> = ({
  CategoriesData = [],
}) => {
  
  return (
    
     <div className=" grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
        {CategoriesData.map((item:any) => (
          <div key={item?.id}>
            <CategoriesCardNew data={item} />
          </div>
        ))}
      </div>
   
  );
};

export default SectionCategoriesGrid;
