const TwiiterLogo =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#00AAEC"
        fillRule="evenodd"
        d="M25 4.55c-.919.418-1.908.701-2.945.827A5.248 5.248 0 0024.31 2.47a10.101 10.101 0 01-3.259 1.274 5.058 5.058 0 00-3.743-1.66c-2.83 0-5.127 2.355-5.127 5.26 0 .412.044.812.132 1.197C8.05 8.32 4.272 6.23 1.74 3.044a5.355 5.355 0 00-.694 2.646c0 1.824.906 3.434 2.282 4.378a5.036 5.036 0 01-2.324-.656v.064c0 2.55 1.768 4.676 4.116 5.157-.43.124-.884.185-1.352.185-.33 0-.653-.031-.965-.093.653 2.089 2.546 3.61 4.79 3.65A10.128 10.128 0 010 20.555a14.28 14.28 0 007.862 2.362c9.436 0 14.593-8.013 14.593-14.963 0-.23-.004-.458-.013-.682A10.526 10.526 0 0025 4.55z"
        clipRule="evenodd"
      ></path>
    </svg>
    
    </>)
}

export default TwiiterLogo