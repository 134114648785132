import React from "react";

interface Props {}

function PhoneIcon(props: Props) {
  const {} = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z"
      ></path>
      <path
        fill="#fff"
        d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 00-1.391.043l-2.393 2.461c-.576-.11-1.734-.47-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 00.043-1.39L6.859 3.512a1 1 0 00-1.391-.087l-2.17 1.861a1 1 0 00-.29.65c-.015.25-.301 6.171 4.29 10.765C11.306 20.707 16.324 21 17.706 21c.202 0 .326-.006.359-.008a.991.991 0 00.648-.29l1.86-2.172a1 1 0 00-.086-1.39l-4.064-3.697z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
