import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import BagIcon from "components/BagIcon";

import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";

import ArrowUpIcon from "shared/Svg/ArrowUpIcon";
import ArrowDownIcon from "shared/Svg/ArrowDownIcon";
import ArrowLeftIcon from "shared/Svg/ArrowLeftIcon";
import ArrowRightIcon from "shared/Svg/ArrowRightIcon";
import LikeButtonNew from "components/LikeButtonNew";
import ProductDiscount from "components/Productdiscount";
import InstockIcon from "shared/Svg/instockIcon";
import SectionProductGird from "components/SectionProductGrid";
import SectionCard from "./SectionCard";
import ZoomModel from "./ZoomModel";
import ShareIcon from "shared/Svg/share";
import ProductCompare from "shared/Svg/ProductCompare";
import SectionHomePageHeading from "components/SectionHomePageHeading";
import NotifyAddTocart from "components/NotifyAddTocart";
import toast from "react-hot-toast";
import NcInputNumber from "components/NcInputNumber";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const LIST_IMAGES_DEMO = [p1, p2, p3, p4];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleArrowUpClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleArrowDownClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < LIST_IMAGES_DEMO.length - 1 ? prevIndex + 1 : prevIndex
    );
  };
  const handleArrowLeftClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleArrowRightClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < LIST_IMAGES_DEMO.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const notifyAddTocart = () => {
    toast.custom(
      (t: any) => (
        <NotifyAddTocart
          name="OIL Seal RR Sprocket DRUM"
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={1}
          show={t.visible}
          sizeSelected={"f"}
          variantActive={1}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const TableData = [
    {
      Quantity: "20-99 items",
      Discount: "20%",
      PriceNet: "₹29.99 ",
    },
    {
      Quantity: "100-299 items",
      Discount: "30%",
      PriceNet: "₹25.35",
    },
    {
      Quantity: "20-99 items",
      Discount: "20%",
      PriceNet: "₹23.35",
    },
  ];
  const TableComponent: React.FC = () => {
    return (
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 ">
                      Quantity
                    </th>
                    <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 ">
                      Discount
                    </th>
                    <th className="px-6 py-3 text-end text-xs font-medium text-gray-500  ml-auto">
                      Price net
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {TableData.map((value: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                          {value?.Quantity}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap text-sm text-[#F52F2F] dark:text-gray-200">
                          {value?.Discount}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-800 text-end dark:text-gray-200 ml-auto">
                          {value?.PriceNet}{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeftArrow = () => {
    const CLASSES =
      "absolute top-1/2 transform -translate-y-1/2 left-3 px-2.5 py-1.5 text-xs  flex items-center justify-center cursor-pointer ";
    return (
      <div className={CLASSES} onClick={handleArrowLeftClick}>
        <button>
          <ArrowLeftIcon />
        </button>
      </div>
    );
  };

  const renderRightArrow = () => {
    const CLASSES =
      "absolute top-1/2 transform -translate-y-1/2 right-3 px-2.5 py-1.5 text-xs  flex items-center justify-center cursor-pointer ";
    return (
      <div className={CLASSES} onClick={handleArrowRightClick}>
        <button>
          <ArrowRightIcon />
        </button>
      </div>
    );
  };

  const [selectedValue, setSelectedValue] = useState("");
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const [qualitySelected, setQualitySelected] = useState(1);

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8 ">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            OIL Seal RR Sprocket DRUM
          </h2>
          <h5 className="text-[#737B7D]">
            Brand name: AGWAN MOTORS PRIVATE LIMITED
          </h5>
          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <InstockIcon />
          </div>
          <TableComponent />
          <div className="flex  justify-end items-end mx-3">
            <h5
              className="text-start text-[#1071FF] "
              style={{ fontFamily: "Basier Square" }}
            >
              {" "}
              <span className=""> your price </span>{" "}
              <span className="text-lg">₹150</span> net{" "}
              <span className="text-[#FF7E27]"> ₹55.00 net </span>{" "}
            </h5>
          </div>
        </div>

        <div className="flex  ">
          <select
            id="dropdown"
            className=" mt-2 border-0 border-b border-gray-500 w-10/12"
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <option value="">Choose variant</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>

          <div className="flex mx-5 mt-5 ">
            <p className="text-start  text-sm lg:text-base mt-1">Item:</p>
            <span className="mx-2">
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </span>
          </div>
        </div>

        <div className="flex gap-5">
          <ButtonPrimary
            className="flex-1 flex-shrink-0 bg-black text-white"
            onClick={notifyAddTocart}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
          <div className="mt-1">
            <button>
              <ShareIcon />
            </button>
          </div>
          <div className="mt-1">
            <button>
              <ProductCompare />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      // image:'https://images.cmsnl.com/img/products/retainerrr-sprocket-drum_big6473315D00-01_a7a2.jpg',
      image: p1,
    },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 5,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 6,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 7,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
    {
      id: 8,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
  ];

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          <div className="w-full lg:w-[45%] flex gap-5 ">
            <div className="w-[15%] lg:w-[15%] ">
              <div className="grid grid-cols-1 gap-3 ">
                <div
                  className="flex justify-center cursor-pointer mb-1"
                  onClick={handleArrowUpClick}
                >
                  <button>
                    <ArrowUpIcon />
                  </button>
                </div>
                {LIST_IMAGES_DEMO.map((item, index) => (
                  <div
                    key={index}
                    className={` cursor-pointer aspect-w-4 lg:aspect-w-2 aspect-h-3 xl:aspect-w-4 2xl:aspect-w-3 aspect-h-4 border rounded-md ${
                      index === currentImageIndex ? "border-black" : ""
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  >
                    <img
                      src={item}
                      className="w-full rounded-md object-cover"
                      alt={`product detail ${index + 1}`}
                    />
                  </div>
                ))}
                <div
                  className="flex justify-center cursor-pointer mt-1"
                  onClick={handleArrowDownClick}
                >
                  <button>
                    <ArrowDownIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[85%] lg:w-[85%] ">
              <div className="relative">
                <div className="aspect-w-16 aspect-h-16">
                  <img
                    src={LIST_IMAGES_DEMO[currentImageIndex]}
                    className="w-full  object-cover"
                    alt="product detail 1"
                  />
                </div>

                <ProductDiscount status="50%" />

                {renderLeftArrow()}
                {renderRightArrow()}
                <ZoomModel ImagesData={currentImageIndex} />

                <LikeButtonNew className="absolute right-3 top-3 " />
              </div>
            </div>
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[55%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>
        <div className="py-5">
          <SectionCard />
        </div>

        <div>
          <SectionHomePageHeading
            heading="Similar parts"
            LastSubHeading="Show all recommended products"
            navigateLink="/show-all-recommended-products"
          />

          <SectionProductGird
            heading="Similar parts"
            ProductData={ProductData}
            subHeading="Show all recommended products"
          />
        </div>
      </main>
    </div>
  );
};

export default ProductDetailPage;
