import MainHeading from "components/Heading/MainHeading";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import SectionCategoriesGrid from "components/SectionCategoriesGrid";
import img1 from "../../images/categories/image (1).png";
import img2 from "../../images/categories/image (2).png";
import img3 from "../../images/categories/image (3).png";
import img4 from "../../images/categories/image (4).png";

const PageShowAllCategory = () => {
  const CategoriesData = [
    {
      id: 1,
      name: "Brake and Clutch",
      image: img1,
    },
    {
      id: 2,
      name: "Engine and Electrical",
      image: img2,
    },
    {
      id: 3,
      name: "Bike Body parts",
      image: img3,
    },
    {
      id: 4,
      name: "Other Parts",
      image: img4,
    },
    {
      id: 5,
      name: "Bike Body parts",
      image: img3,
    },
    {
      id: 6,
      name: "Other Parts",
      image: img4,
    },
    {
      id: 7,
      name: "Brake and Clutch",
      image: img1,
    },
    {
      id: 8,
      name: "Engine and Electrical",
      image: img2,
    },
  ];
  return (
    <>
      <main className="container mt-5 lg:mt-11">
        <Breadcrumb
          item1="Home "
          item1link="/"
          item2=" Show All Category Parts"
        />
        <div className="mt-2">
          <MainHeading MainHeading="All Category parts" />
        </div>
        <SectionCategoriesGrid CategoriesData={CategoriesData} />

        {/* <SectionProductGird ProductData={ProductData} /> */}
      </main>
    </>
  );
};
export default PageShowAllCategory;
