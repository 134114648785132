import { CustomLink } from "data/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "How to buy",
    menus: [
      { href: "how-to-buy/payment-methods", label: "Payment methods" },
      { href: "how-to-buy/order-pickUp", label: "Order and pick up" },
      { href: "how-to-buy/order-delivery", label: "Order with delivery" },
      { href: "how-to-buy/shopping-phone", label: "Shopping over the phone" },
      { href: "exchange-return", label: "Returns" },
    ],
  },
  {
    id: "1",
    title: "Help",
    menus: [
      { href: "contact", label: "Contact" },
      { href: "/", label: "Online Help" },
      { href: "/", label: "Our Commitments" },
      { href: "/", label: "Give feedback" },
    ],
  },
  {
    id: "2",
    title: "About",
    menus: [
      { href: "/about", label: "About us" },
      { href: "/", label: "Press Office" },
      { href: "/", label: "For suppliers" },
      { href: "/", label: "Regulations" },
      { href: "/", label: "Privacy Policy" },
    ],
  },
];

const Footer: React.FC = () => {
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    // Check if the current path is "/signup"
    setShouldHide(location.pathname.includes("/forgot-password"));
  }, [location.pathname]);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                to={item.href}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div
      className={`nc-Footer bg-[/F6F8FB] relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700 ${
        shouldHide ? " hidden" : ""
      }`}
    >
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10  ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-2 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-2">
            <p className="text-xl font-medium">Sign up for news</p>
            <p className="mt-2 text-sm text-justify">
              Keep up to date with the latest product launches and news. Find
              out more about our brands and get special promo codes.
            </p>
            <input
              type="text"
              placeholder="Your e-mail address"
              className="mt-2 border-emerald-50"
            />{" "}
            <br />
            <button className="rounded-full bg-black text-white py-2 px-5  mt-5  ">
              Sign up for newsletter
            </button>
          </div>

          <div className="flex mt-2">
            <input type="checkbox" className="mt-1" />
            <p className="mx-2">
              {" "}
              I accept{" "}
              <span className="text-[/1071FF] text-sm">
                {" "}
                the personal data management.
              </span>{" "}
            </p>
          </div>
        </div>

        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
