import ZoomIcon from "shared/Svg/ZoomIcon";
import XIcon from "shared/Svg/x";
import React, { FC, useEffect, useState } from "react";
import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";
import ArrowLeftIcon from "shared/Svg/ArrowLeftIcon";
import ArrowRightIcon from "shared/Svg/ArrowRightIcon";
export interface ZoomModelProps {
  //    heading?: any;
  ImagesData: any;
  // subHeading?: any;
}
const ZoomModel: FC<ZoomModelProps> = ({ ImagesData = [] }) => {
  const LIST_IMAGES_DEMO = [p1, p2, p3, p4];
  const [showModal, setShowModal] = React.useState(false);
  const handelModelAction = () => {
    setShowModal(false);
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(ImagesData);
  useEffect(() => {
    setCurrentImageIndex(ImagesData);
  }, [ImagesData]);
  const handleArrowLeftClick = () => {
    setCurrentImageIndex((prevIndex: any) =>
      prevIndex > 0 ? prevIndex - 1 : 0
    );
  };

  const handleArrowRightClick = () => {
    setCurrentImageIndex((prevIndex: any) =>
      prevIndex < LIST_IMAGES_DEMO.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const renderZoomIcon = () => {
    const CLASSES =
      "absolute  bottom-3 transform -translate-y-1/2 right-2 px-2.5 py-1.5 text-xs  flex items-center justify-center cursor-pointer ";
    return (
      <div className={CLASSES}>
        <button onClick={() => setShowModal(true)}>
          <ZoomIcon />
        </button>
      </div>
    );
  };

  const renderLeftArrow = () => {
    const CLASSES =
      "absolute top-1/2 transform -translate-y-1/2 left-3 px-2.5 py-1.5 text-xs  flex items-center justify-center cursor-pointer ";
    return (
      <div className={CLASSES} onClick={handleArrowLeftClick}>
        <button>
          <ArrowLeftIcon />
        </button>
      </div>
    );
  };
  const renderRightArrow = () => {
    const CLASSES =
      "absolute top-1/2 transform -translate-y-1/2 right-3 px-2.5 py-1.5 text-xs  flex items-center justify-center cursor-pointer ";
    return (
      <div className={CLASSES} onClick={handleArrowRightClick}>
        <button>
          <ArrowRightIcon />
        </button>
      </div>
    );
  };
  return (
    <>
      {renderZoomIcon()}
      {showModal ? (
        <>
          <div className=" px-10 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative my-8 mx-auto max-w-7xl w-full ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none product-preview-container ">
                <div
                  className="ml-auto m-5 cursor-pointer"
                  onClick={handelModelAction}
                >
                  <XIcon />
                </div>
                <div className="flex justify-center h-96  mt-10">
                  <img
                    src={LIST_IMAGES_DEMO[currentImageIndex]}
                    className="w-full  rounded-md object-contain"
                    alt={`product detail`}
                  />
                  {renderLeftArrow()}
                  {renderRightArrow()}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ZoomModel;
