import React from "react";

interface Props {}

function LocationIconContact(props: Props) {
  const {} = props;

  return (
    <>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M12 1.5a8.26 8.26 0 00-8.25 8.25 8.167 8.167 0 001.662 4.95s.225.296.262.339L12 22.5l6.33-7.465.258-.335v-.002A8.164 8.164 0 0020.25 9.75 8.26 8.26 0 0012 1.5zm0 11.25a3 3 0 110-5.999 3 3 0 010 5.999z"
        ></path>
      </svg>
    </>
  );
}

export default LocationIconContact;
