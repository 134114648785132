import { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

// import { Product, PRODUCTS } from "data/data";
import {  PRODUCTS } from "data/data";


export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const CategoriesCardNew: FC<ProductCardProps> = ({
  className = "",
  data = PRODUCTS[0],
}) => {
  const { name, image } = data;

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        <Link to={"/product-detail"} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
         
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={image}
              className="object-cover w-full h-full drop-shadow-xl"
            />
       
        </div>

        <div className="space-y-4 px-2.5 ">
     

          <div>
            <button className=" w-full bg-[#151515] text-white  p-2 rounded-b h-14 ">
            {name}
            </button>
          </div>
        </div>
      </div>

    
    </>
  );
};

export default CategoriesCardNew;
