// import React from "react";

function TrashProductIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1169_36073)">
        <path
          fill="#434447"
          d="M6 19.5c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12H6v12zm2-10h8v10H8v-10zm7.5-5l-1-1h-5l-1 1H5v2h14v-2h-3.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1169_36073">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TrashProductIcon;