// import React from "react";

function ToBeFilled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="24"
      fill="none"
      viewBox="0 0 124 24"
    >
      <g clipPath="url(#clip0_1173_30183)">
        <path
          fill="#FF7E27"
          d="M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11a7.906 7.906 0 00-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"
        ></path>
      </g>
      <path
        fill="#FF7E27"
        d="M37.11 16h-1.932V7.936h-2.884V6.2h7.7v1.736H37.11V16zm8.3.126c-1.497 0-2.645-.462-3.443-1.4s-1.19-2.156-1.19-3.626c0-1.47.392-2.674 1.19-3.612.797-.938 1.946-1.414 3.444-1.414 1.497 0 2.631.476 3.43 1.414.797.938 1.203 2.142 1.203 3.612 0 1.47-.405 2.688-1.203 3.626-.799.938-1.933 1.4-3.43 1.4zm-2.002-2.618c.448.574 1.12.868 2.002.868.883 0 1.555-.294 2.003-.868.448-.588.672-1.386.672-2.408s-.224-1.82-.672-2.394c-.448-.588-1.12-.882-2.002-.882-.883 0-1.555.294-2.003.882-.447.574-.672 1.372-.672 2.394s.225 1.82.672 2.408zM60.326 16h-4.62V6.2h4.466c1.75 0 2.912.966 2.912 2.548 0 .924-.336 1.596-1.022 2.002v.056c.924.434 1.386 1.218 1.386 2.366 0 1.778-1.176 2.828-3.122 2.828zM57.61 7.894v2.212h2.254c.84 0 1.274-.42 1.274-1.134s-.406-1.078-1.232-1.078H57.61zm0 3.864v2.534h2.422c.966 0 1.456-.49 1.456-1.274 0-.728-.49-1.26-1.456-1.26H57.61zM71.82 16h-6.79V6.2h6.79v1.722h-4.872v2.17h4.438V11.8h-4.438v2.492h4.872V16zm7.679 0H77.58V6.2h6.748v1.722h-4.83v2.422h4.382v1.722h-4.382V16zm8.517 0h-1.931V6.2h1.931V16zm8.637 0h-6.44V6.2h1.918v8.078h4.522V16zm7.752 0h-6.44V6.2h1.918v8.078h4.522V16zm8.102 0h-6.79V6.2h6.79v1.722h-4.872v2.17h4.438V11.8h-4.438v2.492h4.872V16zm5.101 0h-3.346V6.2h3.346c1.442 0 2.618.448 3.514 1.358.896.896 1.344 2.072 1.344 3.542 0 1.4-.448 2.562-1.33 3.5-.882.938-2.058 1.4-3.528 1.4zm-1.54-8.078v6.37h1.414c1.946 0 3.024-1.134 3.024-3.192 0-2.03-1.078-3.178-3.024-3.178h-1.414z"
      ></path>
      <defs>
        <clipPath id="clip0_1173_30183">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ToBeFilled;