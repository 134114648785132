import React from "react";

interface Props {}

function LeftArrowButtonIcon(props: Props) {
  const {} = props;

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_1387_16416)">
          <path
            fill="#1071FF"
            d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1387_16416">
            <path fill="#fff" d="M0 0H24V24H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default LeftArrowButtonIcon;
