// import React, { FC, useEffect, useId, useRef } from "react";
import  { FC} from "react";
import ProductCardNew from "./ProductCardNew";
// import RightArrow from "shared/Svg/RightArrow";

export interface SectionProductGridProps {
  className?: any;
 heading?: any;
 ProductData:any;
  subHeading?: any;
}

const SectionProductGird: FC<SectionProductGridProps> = ({
  className = "",
  ProductData = [],
  // heading,
  // subHeading = "",
}) => {
  





  
  return (
    
    <div className={`nc-SectionSliderProductCard ${className}`} >
      {/* <div className="flex justify-between">
        <h1 className="text-sm lg:text-3xl font-medium">{heading}</h1>
        <p className=" flex     lg:ml-auto flex lg:text-base">
          <span className="hidden sm:inline">
        {subHeading}
          </span>
          <span className=" mx-1    lg:hidden ">Show all</span>
          <span className="mx-1   lg:mx-2 mt-1">
          
            <RightArrow />
            </span>
        </p>
      </div> */}

      <div className=" grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
        {ProductData.map((item:any) => (
          <div key={item?.id}>
            <ProductCardNew data={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionProductGird;
