import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { PRODUCTS } from "data/data";
import ModalQuickView from "./ModalQuickView";
import LikeButtonNew from "./LikeButtonNew";
import InstockIcon from "shared/Svg/instockIcon";
import Outofstock from "shared/Svg/outofstock";
import ImgShare from "shared/Svg/imgshare";
import NcInputNumber from "./NcInputNumber";
import ProductDiscount from "./Productdiscount";
import { Link } from "react-router-dom";
import ShareSmallIcon from "shared/Svg/ShareIcon";
import NotifyAddTocart from "components/NotifyAddTocart";
import toast from "react-hot-toast";

export interface ProductCardProps {
  className?: string;
  data?: any;
  isLiked?: boolean;
}

const ProductCardNew: FC<ProductCardProps> = ({
  className = "",
  data = PRODUCTS[0],
  isLiked,
}) => {
  const { name, status, image, id } = data;
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const [qualitySelected, setQualitySelected] = React.useState(1);
  const notifyAddTocart = () => {
    toast.custom(
      (t: any) => (
        <NotifyAddTocart
          name={name}
          productImage={image}
          qualitySelected={1}
          show={t.visible}
          sizeSelected={"none"}
          variantActive={1}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };
  const handelAddToCart = () => {
    notifyAddTocart();
  };

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent border rounded-lg   ${className}`}
        data-nc-id="ProductCard"
        key={id}
      >
        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
            src={image}
            className="object-cover w-full h-full drop-shadow-xl"
          />

          <ProductDiscount status={status} />

          <LikeButtonNew
            liked={isLiked}
            className="absolute top-3 right-3 z-10"
          />
        </div>
        <div className="flex px-2">
          {status === "limited edition" ? (
            <>
              <Outofstock />{" "}
            </>
          ) : (
            <>
              <InstockIcon />{" "}
            </>
          )}
          <div className="ml-auto">
            <div className="">
              <ImgShare />
            </div>
            <div className="mt-2">
              <ShareSmallIcon />
            </div>
          </div>
        </div>

        <div className="space-y-4 px-2.5 ">
          <div>
            <p className="text-slate-400 text-sm lg:text-base">
              Part No. 0259520
            </p>
          </div>
          <div>
            <Link to="/product-detail">
              <p className="text-black font-bold lg:text-xl overflow-hidden overflow-ellipsis max-h-[3.5rem] h-14  ">
                {name}
              </p>
            </Link>
            <p className="mt-2 text-xs lg: text-base">
              Brand name: AGWAN MOTORS PRIVATE LIMITED{" "}
            </p>
            <p className="mt-2 text-xs  lg: text-base"> HSN Code : 40169330 </p>
            <p
              style={{ fontFamily: "Basier Square" }}
              className="text-[#1071FF] mt-2 text-xs   lg: text-base 	"
            >
              your price <span> ₹ </span>{" "}
              <span className="text-xl"> 8.09/unit </span>
            </p>
          </div>
          <div></div>

          <div className="flex items-start justify-start ">
            <p className="text-start  text-sm lg:text-base mt-1">Item:</p>
            <span className="mx-2">
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </span>
          </div>

          <div>
            <button
              className=" w-full bg-[#151515] text-white rounded-full p-2"
              onClick={handelAddToCart}
            >
              Add to cart{" "}
            </button>
          </div>

          <div className="flex justify-between items-end "></div>
        </div>
      </div>

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCardNew;
