import { Popover, Tab, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { FC, Fragment } from "react";
import UserRoundIcon from "shared/Svg/UserRoundIcon";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const headerLanguage = [
  {
    id: "English",
    name: "English",
    description: "United State",
    href: "##",
    active: true,
  },
  {
    id: "Vietnamese",
    name: "Vietnamese",
    description: "Vietnamese",
    href: "##",
  },
  {
    id: "Francais",
    name: "Francais",
    description: "Belgique",
    href: "##",
  },
  {
    id: "Francais",
    name: "Francais",
    description: "Canada",
    href: "##",
  },
  {
    id: "Francais",
    name: "Francais",
    description: "Belgique",
    href: "##",
  },
  {
    id: "Francais",
    name: "Francais",
    description: "Canada",
    href: "##",
  },
];

interface UserDropdownProps {
  panelClassName?: string;
}
const UserDropdown: FC<UserDropdownProps> = ({ panelClassName = "" }) => {
  const userData: any = useSelector(
    (state: RootState) => state.ContainerData.userData
  );
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div className="UserDropdown hidden md:block">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
             group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <UserRoundIcon />
              <span className="ml-2 text-lg font-medium text-black">
                {userData?.name}
              </span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-96 mt-3.5 right-0 ${panelClassName}`}
              >
                <div className="p-5 rounded-2xl bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="grid gap-8 lg:grid-cols-2">
                    <ul>
                      <li className="p-2 text-lg font-medium	">
                        <a href="/#1">My Profile</a>
                      </li>
                      <li className="p-2 text-lg font-medium	">
                        <a href="/#2">Dashboard</a>
                      </li>
                      <li className="p-2 text-lg font-medium	">
                        <p className="cursor-pointer" onClick={handleLogout}>
                          Logout
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default UserDropdown;
