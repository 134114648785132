import Location from "shared/Svg/Location";
import ShippingIcon from "shared/Svg/ShippingIcon";
import CreditCard from "shared/Svg/credit_card";
import InfoIcon from "shared/Svg/infoIcon";

const SectionCard = () => {
  const CardsData = [
    { id: 1, name: "Safety payment", logo: <CreditCard /> },
    { id: 1, name: "More than 5k stationary shop", logo: <Location /> },
    { id: 1, name: "Within 2-5 business days", logo: <ShippingIcon /> },
    { id: 1, name: "Free shipping and returns", logo: <InfoIcon /> },
  ];
  return (
    <>
      {/* <div className="flex justify-between mt-3">
        <div className=" w-52   h-28  ">
          <div className=" flex bg-[#F6F8FB] justify-center mt-5 ">
            <CreditCard />
          </div>
          <h2 className="text-center text-sm">Safety payment </h2>
        </div>

        <div className=" w-52    ">
          <div className=" flex bg-[#F6F8FB] justify-center mt-5 ">
            <Location />
          </div>
          <h2 className="text-center text-sm">More than 5k stationary shop </h2>
        </div>
        <div className=" w-52    ">
          <div className=" flex bg-[#F6F8FB] justify-center mt-5 ">
            <ShippingIcon />
          </div>
          <h2 className="text-center text-sm">Within 2-5 business days </h2>
        </div>
        <div className=" w-52    ">
          <div className=" flex bg-[#F6F8FB] justify-center mt-5 ">
            <InfoIcon />
          </div>
          <h2 className="text-center text-sm">Free shipping and returns </h2>
        </div>
      </div> */}
      <div className="grid lg:grid-cols-12 gap-5">
        {CardsData?.map((value) => (
          <div className="col-span-3" key={value?.id}>
            <div className="product-info-cards">
              <div className="flex justify-center">{value?.logo}</div>
              <div>
                <h1 className="text-center mt-1">{value?.name}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default SectionCard;
