const InstagramLogo =() => {
    return (<>
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="url(#paint0_radial_353_77063)"
        d="M18.75 1.563H6.25A4.688 4.688 0 001.562 6.25v12.5a4.688 4.688 0 004.688 4.688h12.5a4.688 4.688 0 004.688-4.688V6.25a4.688 4.688 0 00-4.688-4.688z"
      ></path>
      <path
        fill="url(#paint1_radial_353_77063)"
        d="M18.75 1.563H6.25A4.688 4.688 0 001.562 6.25v12.5a4.688 4.688 0 004.688 4.688h12.5a4.688 4.688 0 004.688-4.688V6.25a4.688 4.688 0 00-4.688-4.688z"
      ></path>
      <path
        fill="url(#paint2_radial_353_77063)"
        d="M18.75 1.563H6.25A4.688 4.688 0 001.562 6.25v12.5a4.688 4.688 0 004.688 4.688h12.5a4.688 4.688 0 004.688-4.688V6.25a4.688 4.688 0 00-4.688-4.688z"
      ></path>
      <path
        fill="#fff"
        d="M17.969 8.203a1.172 1.172 0 11-2.344 0 1.172 1.172 0 012.344 0z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.5 16.406a3.906 3.906 0 100-7.812 3.906 3.906 0 000 7.812zm0-1.562a2.344 2.344 0 100-4.688 2.344 2.344 0 000 4.688z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.688 12.188c0-2.626 0-3.938.51-4.941a4.687 4.687 0 012.049-2.049c1.003-.51 2.315-.51 4.94-.51h.626c2.625 0 3.937 0 4.94.51.882.45 1.6 1.167 2.049 2.049.51 1.003.51 2.315.51 4.94v.626c0 2.625 0 3.937-.51 4.94a4.687 4.687 0 01-2.049 2.049c-1.003.51-2.315.51-4.94.51h-.626c-2.625 0-3.937 0-4.94-.51a4.687 4.687 0 01-2.049-2.049c-.51-1.003-.51-2.315-.51-4.94v-.626zm7.5-5.938h.624c1.339 0 2.249.001 2.952.059.685.056 1.035.157 1.28.282.588.3 1.066.777 1.365 1.365.125.245.226.595.282 1.28.058.703.059 1.613.059 2.951v.626c0 1.338-.001 2.248-.059 2.951-.056.685-.157 1.035-.282 1.28a3.125 3.125 0 01-1.365 1.365c-.245.125-.595.226-1.28.282-.703.058-1.613.059-2.951.059h-.626c-1.338 0-2.248-.001-2.951-.059-.685-.056-1.035-.157-1.28-.282a3.125 3.125 0 01-1.365-1.365c-.125-.245-.226-.595-.282-1.28-.058-.703-.059-1.613-.059-2.951v-.626c0-1.338.001-2.248.059-2.951.056-.685.157-1.035.282-1.28.3-.588.777-1.066 1.365-1.365.245-.125.595-.226 1.28-.282.703-.058 1.613-.059 2.951-.059z"
        clipRule="evenodd"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_353_77063"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-55.376 21.809 .051) scale(19.9372)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B13589"></stop>
          <stop offset="0.793" stopColor="#C62F94"></stop>
          <stop offset="1" stopColor="#8A3AC8"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_353_77063"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-65.136 23.255 5.382) scale(17.6517)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E8B7"></stop>
          <stop offset="0.445" stopColor="#FB8A2E"></stop>
          <stop offset="0.715" stopColor="#E2425C"></stop>
          <stop offset="1" stopColor="#E2425C" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_radial_353_77063"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(30.07813 -4.29687 .91906 6.4334 .39 2.344)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.157" stopColor="#406ADC"></stop>
          <stop offset="0.468" stopColor="#6A45BE"></stop>
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
    
    </>)
}

export default InstagramLogo