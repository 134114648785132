import ToBeFilled from "shared/Svg/ToBeFilled";

const SectionGrid2 = () => {
  return (
    <>
      <div className="bg-[#E6EFFB] py-5 px-2 mt-5">
        <div className="grid grid-cols-12 gap-3">
          <div className=" col-span-6 lg:col-span-3">
            <div className="flex">
              <h4 className="font-medium"> Items: </h4>{" "}
              <span className="mx-2"> 23</span>
            </div>
            <div className="flex">
              <h4 className="font-medium"> Availability: </h4>{" "}
              <span className="mx-1"> 8-12 days</span>
            </div>
          </div>
          <div className=" col-span-6 lg:col-span-3">
            {" "}
            <div className="flex">
              <h4 className="font-medium "> Shipping method: </h4>{" "}
              <span className="text-[#737B7D] mx-2"> To be filled</span>
            </div>
            <div className="flex">
              <h4 className="font-medium "> Payment method: </h4>{" "}
              <span className="text-[#737B7D] mx-2"> To be filled</span>
            </div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex justify-center items-end">
            {" "}
            <h4 className="font-medium "> Total price: </h4>{" "}
            <span className="text-[#737B7D] mx-2">₹1,154 </span>
          </div>
          <div className="col-span-6 lg:col-span-3  flex justify-end">
            {" "}
            <div className=" ">
              <span className="mx-16">Status</span>
              <ToBeFilled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SectionGrid2;
