

function RightArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#151515"
        d="M8 .168l-1.41 1.41 5.58 5.59H0v2h12.17l-5.58 5.59L8 16.168l8-8-8-8z"
      ></path>
    </svg>
  );
}

export default RightArrow;