import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Braking",
    children: [
      { id: ncNanoId(), href: "/", name: "Brake Disc Caliper" },
      { id: ncNanoId(), href: "/", name: "Brake Disc Plate" },
      { id: ncNanoId(), href: "/", name: "Brake Drum" },
      { id: ncNanoId(), href: "/", name: "Master Cylinder Assembly" },
      { id: ncNanoId(), href: "/", name: "Front Brake Drum" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Electricals",
    children: [
      { id: ncNanoId(), href: "/", name: "Body Control Unit" },
      { id: ncNanoId(), href: "/", name: "CDI" },
      { id: ncNanoId(), href: "/", name: "Digital Meter Worm Speed" },
      { id: ncNanoId(), href: "/", name: "Engine Control Unit(ECU)" },
      // { id: ncNanoId(), href: "/", name: "Cart Page" },
      // { id: ncNanoId(), href: "/", name: "Checkout Page" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Transmission",
    children: [
      { id: ncNanoId(), href: "/", name: "Chain Sprocket" },
      { id: ncNanoId(), href: "/", name: "Clutch Assembly" },
      { id: ncNanoId(), href: "/", name: "Clutch Cover" },
      { id: ncNanoId(), href: "/", name: "Clutch Plates" },
      { id: ncNanoId(), href: "/", name: "Clutch Pulley" },
      { id: ncNanoId(), href: "/", name: "Clutch Shoe" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Other Spares",
    children: [
      { id: ncNanoId(), href: "/", name: "Wheel Run" },
      // { id: ncNanoId(), href: "/blog-single", name: "Blog Single" },
      // { id: ncNanoId(), href: "/about", name: "About Page" },
      // { id: ncNanoId(), href: "/contact", name: "Contact Page" },
      // { id: ncNanoId(), href: "/login", name: "Login" },
      // { id: ncNanoId(), href: "/signup", name: "Signup" },
    ],
  },
];
const OTHER_PAGE_CHILD: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Avenger 150, 180",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Avenger 220",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Boxer 150 ",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Avenger 220",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Caliber",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Avenger 220",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Caliber",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bajaj Caliber",
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Spares by Bikes",
    // children: MEGAMENU_TEMPLATES,
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Spares by Category",
    type: "megaMenu",
    children: MEGAMENU_TEMPLATES,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Bike Bodypart",
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },

  {
    id: ncNanoId(),
    href: "/order-tracking",
    name: "Order Tracking",
    // type: "dropdown",
    // children: OTHER_PAGE_CHILD,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Templates",
  //   type: "megaMenu",
  //   children: MEGAMENU_TEMPLATES,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Explore",
  //   type: "dropdown",
  //   children: OTHER_PAGE_CHILD,
  // },
];
