import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import React, { FC } from "react";

import { Helmet } from "react-helmet-async";
import FastDelivery from "shared/Svg/FastDelivery";
import LiveChatIcon from "shared/Svg/LiveChatIcon";
import SecureIcon from "shared/Svg/SecureIcon";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const AboutUsCardData = [
    {
      id: 1,
      Title: "Live Chat",
      SubTitle: "TALK TO AN AGENT",
      Logo: <LiveChatIcon />,
    },
    {
      id: 2,
      Title: "Fast Delivery",
      SubTitle: "WE DELIVER ALL OVER INDIA",
      Logo: <FastDelivery />,
    },
    {
      id: 1,
      Title: "Secure Payment",
      SubTitle: "SECURE AND RELIABLE PAYMENT ALWAYS",
      Logo: <LiveChatIcon />,
    },
  ];
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About </title>
      </Helmet>

      <div className="container py-10 lg:py-10">
        <Breadcrumb
          item1="Home"
          item1link="/"
          item2="About"
          item2link="/about"
          item3link="/about"
          item3="About US"
        />
        <div className="py-5">
          <h1 className="text-center text-4xl font-medium"> About Us </h1>
        </div>
        <div className="py-5">
          <p className="text-justify text-xl lg:px-10 md:px-10 sm:px-10 ">
            ‘Do Paiya Part’ is guided by four principles: customer obsession
            rather than competitor focus, passion for invention, commitment to
            operational excellence, and long-term thinking. Customer reviews,
            1-Click shopping, personalized recommendations, Prime, Fulfillment
            by Do panel, AWS, Kindle Direct Publishing, Kindle, Career Choice,
            Fire tablets,
          </p>
        </div>
        <div className="px-10 py-5">
          <div className="grid lg:grid-cols-12 gap-5">
            {AboutUsCardData?.map((value) => (
              <>
                <div className="col-span-12 lg:col-span-4 md:col-span-4 ">
                  <div className="about-us-card relative">
                    <div className="bg-[#C4C4C4] w-[auto] h-[9.71px] absolute  top-0 left-[10%] right-[10%]"></div>
                    <div className="bg-[#C4C4C4] w-[auto] h-[9.71px] absolute  bottom-0 left-[10%] right-[10%]"></div>
                    <div className="flex justify-center">{value?.Logo}</div>
                    <h1 className="font-extrabold text-lg text-center py-12 ">
                      {value?.Title}
                    </h1>
                    <h1 className="font-medium text-sm text-center py-5 ">
                      {value?.SubTitle}
                    </h1>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
