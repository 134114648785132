import {
  GetAllBrand,
  VerifyEmail,
  VerifyEmailOTP,
  VerifyMobile,
  VerifyMobileOTP,
  Register,
} from "containers/store";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function SignUpForm() {
  const Navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const VerifyEmailOTPData: any = useSelector(
    (state: RootState) => state.ContainerData.verifyEmail
  );

  const VerifyMobileOTPData: any = useSelector(
    (state: RootState) => state.ContainerData.verifyMobile
  );
  const VerifyEmailOTPResponseData: any = useSelector(
    (state: RootState) => state.ContainerData.verifyEmailOTP
  );
  const VerifyMobileOTPResponseData: any = useSelector(
    (state: RootState) => state.ContainerData.verifyMobileOTP
  );
  const GetAllBrandsData: any = useSelector(
    (state: RootState) => state.ContainerData.getAllBrand
  );

  const dispatch = useDispatch();

  const [EmailVerifyTrue, setEmailVerifyTrue] = useState(false);
  const [EmailOTPData, SetEmailOTPData] = useState(["", "", "", ""]);
  const [MobileOTPVerify, SetMobileOTPVerify] = useState(false);
  const [EmailOTPVerify, SetEmailOTPVerify] = useState(false);
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [MobileOTPData, SetMobileOTPData] = useState(["", "", "", ""]);
  const [MobileVerifyTrue, setMobileVerifyTrue] = useState(false);
  const [EmailLoader, setEmailLoader] = useState(false);
  const [PhoneLoader, setPhoneLoader] = useState(false);
  const [EmailOtpError, setEmailOtpError] = useState(false);
  const [PhoneOtpError, setPhoneOtpError] = useState(false);
  const [VerificationFormError, setVerificationFormError] = useState(false);
  const [ApiErrors, setApiErrors] = useState<string[]>([]);

  const handleEmailOTPChange = (e: any, index: any) => {
    const newValue = e.target.value;
    const newOTP = [...EmailOTPData];
    newOTP[index] = newValue;
    SetEmailOTPData(newOTP);

    // Automatically move to the next input if a digit is entered
    if (newValue !== "" && index < EmailOTPData.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleEmailOTPKeyDown = (e: any, index: any) => {
    if (e.key === "Backspace" && index > 0 && EmailOTPData[index] === "") {
      // Move to the previous input if Backspace is pressed and current input is empty
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleMobileOTPChange = (e: any, index: any) => {
    const newValue = e.target.value;
    const newOTP = [...MobileOTPData];
    newOTP[index] = newValue;
    SetMobileOTPData(newOTP);

    // Automatically move to the next input if a digit is entered
    if (newValue !== "" && index < MobileOTPData.length - 1) {
      const nextInput = document.getElementById(`otp-phone-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleMobileOTPKeyDown = (e: any, index: any) => {
    if (e.key === "Backspace" && index > 0 && MobileOTPData[index] === "") {
      // Move to the previous input if Backspace is pressed and current input is empty
      const prevInput = document.getElementById(`otp-phone-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const validateEmail = (value: any) => {
    // Regular expression for basic email validation
    const emailPattern = /^[A-Za-z0-9+_.-]+@(.+)$/;
    // Test the value against the regex pattern
    const isValid = emailPattern.test(value);
    setIsValidEmail(isValid);
  };
  const handleEmailChange = (e: any) => {
    const newValue = e?.target?.value;
    setEmail(newValue);
    validateEmail(newValue);
  };

  const handelEmailVerify = async () => {
    const PayLoad = {
      email: Email,
    };
    setEmailLoader(true);
    await dispatch(VerifyEmail(PayLoad));
    setEmailLoader(false);
  };
  const handelEmailVerifyOTP = () => {
    const payLoadOTP = EmailOTPData.join("");
    const PayLoad = {
      email: Email,
      otp: payLoadOTP,
      type: "email",
    };

    dispatch(VerifyEmailOTP(PayLoad));
  };
  const handleMobileVerify = async () => {
    const PayLoad = {
      mobile: Mobile,
    };

    setPhoneLoader(true);
    await dispatch(VerifyMobile(PayLoad));
    setPhoneLoader(false);
  };
  const handelMobileVerifyOTP = () => {
    const payLoadOTP = MobileOTPData.join("");
    const PayLoad = {
      mobile: Mobile,
      otp: payLoadOTP,
      type: "mobile",
    };

    dispatch(VerifyMobileOTP(PayLoad));
  };

  useEffect(() => {
    if (VerifyEmailOTPData?.success === true) {
      setEmailVerifyTrue(true);
    }
  }, [VerifyEmailOTPData]);
  useEffect(() => {
    if (VerifyMobileOTPData?.success === true) {
      setMobileVerifyTrue(true);
    }
  }, [VerifyMobileOTPData]);
  useEffect(() => {
    if (VerifyEmailOTPResponseData?.success === true) {
      SetEmailOTPVerify(true);
    } else if (VerifyEmailOTPResponseData?.success === false) {
      setEmailOtpError(true);
    }
  }, [VerifyEmailOTPResponseData]);
  useEffect(() => {
    if (VerifyMobileOTPResponseData?.success === true) {
      SetMobileOTPVerify(true);
    } else if (VerifyMobileOTPResponseData?.success === false) {
      setPhoneOtpError(true);
    }
  }, [VerifyMobileOTPResponseData]);
  useEffect(() => {
    dispatch(GetAllBrand());
  }, [dispatch]);

  const onSubmit = (data: any) => {
    if (EmailOTPVerify && MobileOTPVerify) {
      dispatch(Register(data)).then((data: any) => {
        if (data.payload?.register.success) {
          Navigate("/");
        } else if (
          typeof data.payload?.register.errors == "object" &&
          data.payload?.register.errors
        ) {
          const tempData = data?.payload?.register.errors;
          setApiErrors([]);
          Object.keys(tempData).map((i) => {
            setApiErrors((prevData) => [...prevData, tempData[i]]);
          });
          setVerificationFormError(true);
        } else {
          setApiErrors((prevData) => [
            ...prevData,
            data?.payload.register.message,
          ]);
        }
      });
    } else {
      setVerificationFormError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="mt-10 mx-1 lg:mx-20">
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            <div>
              <label className="font-semibold	"> Name </label> <br />
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.name && typeof errors.name?.message === "string" && (
                <span className="text-red-500">{errors.name?.message}</span>
              )}
            </div>

            <div>
              <label className="font-semibold	"> Dealer Name </label> <br />
              <Controller
                name="dealer_name"
                control={control}
                rules={{ required: "Dealer Name is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.dealer_name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.dealer_name &&
                typeof errors.dealer_name?.message === "string" && (
                  <span className="text-red-500">
                    {errors.dealer_name?.message}
                  </span>
                )}
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 md:w-6/12 items-center"></div>
            <div className="w-1/2 md:w-6/12 items-center"></div>
          </div>

          {!EmailVerifyTrue && (
            <>
              <div className="flex mt-2">
                <div>
                  <label className="font-semibold	"> Email </label> <br />
                  <input
                    type="text"
                    className=" w-auto lg:w-[30rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 "
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="mt-5 mx-4 ">
                  {EmailLoader ? (
                    <div className="flex justify-center items-center w-[100%] h-[100%]">
                      <Spinner
                        className="h-8 w-8"
                        onClick={() => {}}
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                      />
                    </div>
                  ) : isValidEmail ? (
                    <div
                      className="border border-black cursor-pointer bg-white px-10 py-2 font-semibold rounded-md w-36"
                      onClick={handelEmailVerify}
                    >
                      Verify
                    </div>
                  ) : (
                    <div
                      className="border border-black cursor-pointer bg-white px-10 py-2 font-semibold rounded-md w-36"
                      onClick={() => {
                        setEmailError(true);
                      }}
                    >
                      Verify
                    </div>
                  )}
                </div>
              </div>
              {EmailError && (
                <span className="text-red-500">Invalid email address </span>
              )}
            </>
          )}

          {EmailVerifyTrue && !EmailOTPVerify && (
            <>
              <div className="flex items-center mt-2  ">
                <div className="flex items-center ">
                  <div className="w-1/2 md:w-96 items-start">
                    <label className="font-semibold"> Email </label> <br />
                    <input
                      type="email"
                      className="border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-11/12"
                      value={Email}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 md:w-60 items-start">
                    <div className="flex flex-col mt-2">
                      <div className="flex flex-row items-center gap-3 w-full max-w-xs mt-4">
                        {EmailOTPData.map((digit, index) => (
                          <input
                            key={index}
                            type="number"
                            inputMode="numeric"
                            className="number-input w-full h-full flex flex-col items-center justify-center text-center p-2 outline-none rounded-xl border border-[#000000] text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700 text-black"
                            id={`otp-input-${index}`}
                            value={digit}
                            onChange={(e) => handleEmailOTPChange(e, index)}
                            onKeyDown={(e) => handleEmailOTPKeyDown(e, index)}
                            onInput={(e: any) => {
                              if (e?.target.value?.length > 1) {
                                e.target.value = e.target.value.slice(0, 1);
                              }
                            }}
                          />
                        ))}
                      </div>
                    </div>

                    {EmailOtpError && (
                      <span className="text-red-500">Invalid OTP </span>
                    )}
                  </div>
                  <div className="mt-5 ml-16">
                    <div
                      className="border cursor-pointer border-black bg-white px-10 py-2 font-semibold rounded-md w-36"
                      onClick={handelEmailVerifyOTP}
                    >
                      Verify
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {EmailOTPVerify && (
            <>
              <label className="font-semibold"> Email </label> <br />
              <div className="flex items-center">
                <Controller
                  name="email"
                  control={control}
                  defaultValue={Email}
                  rules={{ required: "Email is required" }} // Add required rule here
                  render={({ field }) => (
                    <input
                      type="email"
                      className="border-b border-0 border-green-500 focus:outline-none focus:border-0 w-5/12"
                      {...field}
                      disabled
                    />
                  )}
                />
                <br></br>
                {errors.email && (
                  <span className="text-red-500">Email is required</span>
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-patch-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                </svg>
              </div>
            </>
          )}

          {!MobileVerifyTrue && (
            <div className="flex mt-2">
              <div>
                <label className="font-semibold	"> Phone Number </label> <br />
                <input
                  type="text"
                  className="  w-auto lg:w-[30rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 "
                  onChange={(e) => setMobile(e?.target?.value)}
                  onInput={(e: any) => {
                    if (e?.target.value?.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
              </div>
              <div className="mt-5 mx-4 ">
                {PhoneLoader ? (
                  <div className="flex justify-center items-center w-[100%] h-[100%]">
                    <Spinner
                      className="h-8 w-8"
                      onClick={() => {}}
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                    />
                  </div>
                ) : (
                  <div
                    className="border cursor-pointer border-black bg-white px-10 py-2  font-semibold	rounded-md w-36	"
                    onClick={handleMobileVerify}
                  >
                    Verify
                  </div>
                )}
              </div>
            </div>
          )}
          {MobileVerifyTrue && !MobileOTPVerify && (
            <div className="flex items-center mt-2  ">
              <div className="flex items-center ">
                <div className="w-1/2 md:w-96 items-start">
                  <label className="font-semibold	"> Phone Number </label> <br />
                  <input
                    type="number"
                    className="border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-11/12"
                    value={Mobile}
                    disabled
                  />
                </div>
                <div className="w-1/2 md:w-60 items-start">
                  <div className="flex flex-col mt-2">
                    <div className="flex flex-row items-center gap-3 w-full max-w-xs mt-4">
                      {MobileOTPData.map((digit, index) => (
                        <input
                          key={index}
                          type="number"
                          inputMode="numeric"
                          className="number-input w-full h-full flex flex-col items-center justify-center text-center p-2 outline-none rounded-xl border border-[#000000] text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700 text-black"
                          id={`otp-phone-input-${index}`}
                          value={digit}
                          onChange={(e) => handleMobileOTPChange(e, index)}
                          onKeyDown={(e) => handleMobileOTPKeyDown(e, index)}
                          onInput={(e: any) => {
                            if (e?.target.value?.length > 1) {
                              e.target.value = e.target.value.slice(0, 1);
                            }
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  {PhoneOtpError && (
                    <span className="text-red-500">Invalid OTP </span>
                  )}
                </div>
              </div>
              <div className="mt-5 ml-16 ">
                <div
                  className="border cursor-pointer border-black bg-white px-10 py-2  font-semibold	rounded-md w-36"
                  onClick={handelMobileVerifyOTP}
                >
                  Verify
                </div>
              </div>
            </div>
          )}
          {MobileOTPVerify && (
            <>
              <br />
              <label className="font-semibold"> Phone Number </label> <br />
              <div className="flex items-center">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={Mobile}
                  rules={{ required: "Phone is required" }} // Add required rule here
                  render={({ field }) => (
                    <input
                      type="number"
                      className="border-0 border-b border-green-500  focus:border-0 w-5/12"
                      {...field}
                      disabled
                    />
                  )}
                />
                <br></br>
                {errors.phone && (
                  <span className="text-red-500">Phone is required</span>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-patch-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                </svg>
              </div>
            </>
          )}
          <div className="flex mt-2">
            <div>
              <label className="font-semibold	"> Brand </label> <br />
              <Controller
                name="brand"
                control={control}
                defaultValue=""
                rules={{ required: "brand is required" }}
                render={({ field }) => (
                  <>
                    <select
                      {...field}
                      id="dropdown"
                      className={`rounded-lg mt-2 ${
                        errors?.brand
                          ? "border-red-600 focus:border-red-600"
                          : "border-gray-600 focus:border-gray-600"
                      }`}
                      onChange={(e) => {
                        field.onChange(e);
                        // Your additional onChange logic here if needed
                      }}
                    >
                      <option value="">Select Brand</option>
                      {GetAllBrandsData.map((brand: any) => (
                        <option key={brand?.id} value={brand?.id}>
                          {brand?.name}
                        </option>
                      ))}
                    </select>
                    <br />
                    {errors.brand &&
                      typeof errors.brand?.message === "string" && (
                        <span className="text-red-500">
                          {errors.brand?.message}
                        </span>
                      )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex mt-2">
            <div>
              <label className="font-semibold	"> GST number </label> <br />
              <Controller
                name="gst_no"
                control={control}
                rules={{ required: "gst is required" }}
                render={({ field }) => (
                  <input
                    type="text"
                    className={` w-auto lg:w-[50rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-[50rem]${
                      errors?.gst_no ? "border-red-500" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.gst_no && typeof errors.gst_no?.message === "string" && (
                <span className="text-red-500">{errors.gst_no?.message}</span>
              )}
            </div>
          </div>
          <div className="flex mt-2">
            <div>
              <label className="font-semibold	"> Company Name </label> <br />
              <Controller
                name="company_name"
                control={control}
                rules={{ required: "Company Name is required" }}
                render={({ field }) => (
                  <input
                    type="text"
                    className={`  w-auto lg:w-[50rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-[50rem]${
                      errors?.company_name ? "border-red-500" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.company_name &&
                typeof errors.company_name?.message === "string" && (
                  <span className="text-red-500">
                    {errors.company_name?.message}
                  </span>
                )}
            </div>
          </div>
          <div className="flex mt-2">
            <div>
              <label className="font-semibold	"> Address </label> <br />
              <Controller
                name="address"
                control={control}
                rules={{ required: "Address is required" }}
                render={({ field }) => (
                  <input
                    type="text"
                    className={` w-auto lg:w-[50rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-[50rem]${
                      errors?.address ? "border-red-500" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.address &&
                typeof errors.address?.message === "string" && (
                  <span className="text-red-500">
                    {errors.address?.message}
                  </span>
                )}
            </div>
          </div>
          <div className="flex mt-2">
            <div>
              <label className="font-semibold	"> Address (2) </label> <br />
              <Controller
                name="second_address"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <input
                    type="text"
                    className={` w-auto lg:w-[50rem] border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-[50rem]${
                      errors?.second_address ? "border-red-500" : ""
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.second_address &&
                typeof errors.second_address?.message === "string" && (
                  <span className="text-red-500">
                    {errors.second_address?.message}
                  </span>
                )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-3">
            <div>
              <label className="font-semibold	"> Company start date </label>{" "}
              <br />
              <Controller
                name="company_start_date"
                control={control}
                rules={{ required: " Company start date is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="date"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.company_start_date ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.company_start_date &&
                typeof errors.company_start_date?.message === "string" && (
                  <span className="text-red-500">
                    {errors.company_start_date?.message}
                  </span>
                )}
            </div>

            <div>
              <label className="font-semibold	"> Pan number </label> <br />
              <Controller
                name="panno"
                control={control}
                rules={{ required: "pan nubmer is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.panno ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.panno && typeof errors.panno?.message === "string" && (
                <span className="text-red-500">{errors.panno?.message}</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-3">
            <div>
              <label className="font-semibold	"> Password </label> <br />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                }}
                render={({ field }) => (
                  <input
                    type="password"
                    className={`border-0 border-b border-gray-500 focus:outline-none w-10/12 ${
                      errors.password ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              <div></div>
              {errors.password &&
                typeof errors.password?.message === "string" && (
                  <span className="text-red-500">
                    {errors.password?.message}
                  </span>
                )}
            </div>

            <div>
              <label className="font-semibold	">Re Type Password</label> <br />
              <Controller
                name="retypepassword"
                control={control}
                rules={{
                  required: "Retype password is required",
                  validate: (value) =>
                    value === getValues("password") || "Passwords do not match",
                }}
                render={({ field }) => (
                  <input
                    type="password"
                    className={`border-0 border-b border-gray-500 focus:outline-none w-10/12 ${
                      errors.name ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.retypepassword &&
                typeof errors.retypepassword?.message === "string" && (
                  <span className="text-red-500">
                    {errors.retypepassword?.message}
                  </span>
                )}
            </div>
          </div>
          <p className="text-xl font-semibold mt-4	 ">
            {" "}
            Bank Account Details (optional)
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-3">
            <div>
              <label className="font-semibold	"> Account Number </label> <br />
              <Controller
                name="accountno"
                control={control}
                rules={{ required: "Account No is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.accountno ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.accountno &&
                typeof errors.accountno?.message === "string" && (
                  <span className="text-red-500">
                    {errors.accountno?.message}
                  </span>
                )}
            </div>

            <div>
              <label className="font-semibold	">IFSC Code </label> <br />
              <Controller
                name="ifsc_code"
                control={control}
                rules={{ required: "IFSC is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.ifsc_code ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.ifsc_code &&
                typeof errors.ifsc_code?.message === "string" && (
                  <span className="text-red-500">
                    {errors.ifsc_code?.message}
                  </span>
                )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-3">
            <div>
              <label className="font-semibold	">Bank Name </label> <br />
              <Controller
                name="bank"
                control={control}
                rules={{ required: "Bank Name is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.bank ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.bank && typeof errors.bank?.message === "string" && (
                <span className="text-red-500">{errors.bank?.message}</span>
              )}
            </div>

            <div>
              <label className="font-semibold	">Branch Code </label> <br />
              <Controller
                name="branch"
                control={control}
                rules={{ required: "Branch code is required" }} // Add required rule here
                render={({ field }) => (
                  <input
                    type="text"
                    className={`border-0 border-b border-gray-500 focus:outline-none focus:border-0 w-10/12 ${
                      errors.branch ? "border-red-500" : "" // Apply red border if there is an error
                    }`}
                    {...field}
                  />
                )}
              />
              <br></br>
              {errors.branch && typeof errors.branch?.message === "string" && (
                <span className="text-red-500">{errors.branch?.message}</span>
              )}
            </div>
          </div>
        </div>

        {VerificationFormError &&
          (!EmailOTPVerify || !MobileOTPVerify || ApiErrors.length > 0) && (
            <>
              <br />
              <br />
              <div
                className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Danger</span>
                <div>
                  <span className="font-medium">
                    Ensure that these requirements are met:
                  </span>
                  <ul className="mt-1.5 list-disc list-inside">
                    {!EmailOTPVerify && <li>Please Verify Your Email First</li>}
                    {!MobileOTPVerify && (
                      <li>Please Verify Your Phone First</li>
                    )}
                    {ApiErrors.map((i, index) => {
                      return <li key={index}>{i}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </>
          )}
        <div className="flex  justify-center mt-10 items-center">
          <button
            type="submit"
            className="bg-[#000] text-white p-2 rounded-md w-32 "
          >
            {" "}
            Register
          </button>
        </div>
      </div>
    </form>
  );
}

export default SignUpForm;
