import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ProductCompareCard from "components/ProductCompareCard";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import TrashIcon from "shared/Svg/TrashIcon";
import p1 from "../../images/productImg/p1.png";
import p2 from "../../images/productImg/p2.png";
import p3 from "../../images/productImg/p3.png";
import p4 from "../../images/productImg/p4.png";
import PrinterIcon from "shared/Svg/PrinterIcon";
import ArrowLeftIcon from "shared/Svg/ArrowLeftIcon";
import ArrowRightIcon from "shared/Svg/ArrowRightIcon";
import LeftArrowButtonIcon from "shared/Svg/LeftArrowButtonIcon";
import RightArrowButtonIcon from "shared/Svg/RightArrowButtonIcon";

function PageCompare() {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const ProductData = [
    {
      id: 1,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 8.09,
      image: p1,
    },
    {
      id: 2,
      name: "SPLIT PIN 3.2x25 ZNB",
      description: "Dark pink 2023",
      price: 8.09,
      image: p2,
    },
    {
      id: 3,
      name: "HEX BOLT+WAVE SPR WASHER  M8x16",
      description: "Dark pink 2023",
      price: 3.09,
      image: p3,
    },
    {
      id: 4,
      name: "OIL Seal RR Sprocket DRUM",
      description: "Dark pink 2023",
      price: 4.09,
      image: p4,
    },
  ];
  return (
    <>
      <div className="container py-5">
        <Breadcrumb
          item1="Home"
          item1link="/"
          item2="Compare"
          item2link="/compare"
        />

        <div className="flex py-5">
          <h1 className="text-sm lg:text-4xl font-medium">Compare (4 parts)</h1>
          <div className="ml-auto flex gap-2 items-center">
            <TrashIcon /> <span> Remove All </span>
          </div>
        </div>
        {/* Form */}
        <div className=" py-5 flex gap-5 flex-wrap">
          <div className="">
            <label className="font-semibold	"> Brand </label> <br />
            <Controller
              name="brand"
              control={control}
              defaultValue=""
              rules={{ required: "brand is required" }}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    id="dropdown"
                    className={`rounded-lg mt-2 ${
                      errors?.brand
                        ? "border-red-600 focus:border-red-600"
                        : "border-gray-600 focus:border-gray-600"
                    }`}
                    onChange={(e) => {
                      field.onChange(e);
                      // Your additional onChange logic here if needed
                    }}
                  >
                    <option value="">Select Brand</option>
                  </select>
                  <br />
                  {errors.brand &&
                    typeof errors.brand?.message === "string" && (
                      <span className="text-red-500">
                        {errors.brand?.message}
                      </span>
                    )}
                </>
              )}
            />
          </div>
          <div className="">
            <label className="font-semibold	"> Part Number </label> <br />
            <Controller
              name="PartNumber"
              control={control}
              defaultValue=""
              rules={{ required: "brand is required" }}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    id="dropdown"
                    className={`rounded-lg mt-2 ${
                      errors?.brand
                        ? "border-red-600 focus:border-red-600"
                        : "border-gray-600 focus:border-gray-600"
                    }`}
                    onChange={(e) => {
                      field.onChange(e);
                      // Your additional onChange logic here if needed
                    }}
                  >
                    <option value="">Select Part Number</option>
                  </select>
                  <br />
                  {errors.PartNumber &&
                    typeof errors.PartNumber?.message === "string" && (
                      <span className="text-red-500">
                        {errors.PartNumber?.message}
                      </span>
                    )}
                </>
              )}
            />
          </div>
          <div className="flex items-end ">
            <button className=" p-2 rounded-full border text-[#1071FF] border-[#1071FF] ">
              Add to Compare
            </button>
          </div>
        </div>
        {/* Form End */}
        <div className="flex">
          <div className="flex gap-2 items-center">
            <PrinterIcon /> <span> Print Page </span>
          </div>
          <div className=" flex gap-2 ml-auto">
            <button className="rounded-lg border border-primary p-2 ">
              <LeftArrowButtonIcon />
            </button>
            <button className="rounded-lg border border-primary p-2 ">
              <RightArrowButtonIcon />
            </button>
          </div>
        </div>
        <div className=" grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
          {/* <ProductCompareCard /> */}
          {ProductData.map((item: any) => (
            <div key={item?.id}>
              <ProductCompareCard data={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PageCompare;
