import { FC } from "react";

export interface MainHeadingProps {
    MainHeading?: string;
    
  }


const MainHeading :FC <MainHeadingProps> = ({MainHeading}) => {
    return (<>
      <h1 className="text-2xl font-medium"> 
    {MainHeading}

      </h1>
    
    </>)
}

export default MainHeading