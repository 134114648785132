import { useState } from "react";
import { Link, Router, useNavigate } from "react-router-dom";
import XIcon from "shared/Svg/x";

export const renderMagnifyingGlassIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_305_41368)">
        <path
          fill="#151515"
          d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_305_41368">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
function SearchProductModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const searchTableData = [
    {
      id: "1",
      ProductNumber: "0259520",
      Description: "BALL BEARING 25x52x15 RS1C3 6205	",
      Price: "47.50",
    },
    {
      id: "2",
      ProductNumber: "0259520",
      Description: "BALL BEARING 15x42x13 C3 6302Z	",
      Price: "47.50",
    },
    {
      id: "3",
      ProductNumber: "0259520",
      Description: "BALL BEARING 25x52x15 CE 6205	",
      Price: "47.50",
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = () => {
    setModalIsOpen(false);
    navigate("/advance-search");
  };
  return (
    <>
      <button className="flex gap-1 search-btn" onClick={openModal}>
        {" "}
        {renderMagnifyingGlassIcon()}
        <span className="hidden sm:inline">Search by product or SKU</span>
      </button>

      {modalIsOpen && (
        <>
          <div className=" container   flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative  w-full ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none product-preview-container px-10 ">
                <div className="absolute right-5 top-5 lg:right-8">
                  <div className=" cursor-pointer" onClick={closeModal}>
                    <XIcon />
                  </div>
                </div>
                {/* Heading Search */}
                <div className="flex flex-wrap lg: mt-10  gap-5">
                  <div className="search-container-modal">
                    {renderMagnifyingGlassIcon()}
                    <input
                      type="text"
                      className="input-search-modal"
                      placeholder="Search by product or SKU"
                    />
                  </div>
                  <button className="bg-[#151515] rounded-lg text-white mx-2   search-btn-modal">
                    Search
                  </button>
                </div>
                {/* Table */}
                <div className="p-5">
                  <table className="table-auto w-full border">
                    <thead className="border">
                      <tr>
                        <th>NO</th>
                        <th>Product Number</th>
                        <th>Product Description</th>
                        <th>Unit Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchTableData?.map((value) => (
                        <>
                          <tr>
                            <td className="text-center">{value?.id}</td>
                            <td className="text-center">
                              {value?.ProductNumber}
                            </td>
                            <td className="text-center">
                              {value?.Description}
                            </td>
                            <td className="text-center">₹{value?.Price}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Table End */}

                {/* Advanced Search Button */}
                <div className=" p-5 flex justify-end">
                  <button
                    className="bg-[#151515] rounded-full text-white p-2"
                    onClick={handleNavigate}
                  >
                    Advance search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}

export default SearchProductModal;
