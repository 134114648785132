

function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_1102_43082)">
        <rect
          width="32"
          height="32"
          fill="#F6F8FB"
          rx="8"
          transform="matrix(1 0 0 -1 0 32)"
        ></rect>
        <path
          fill="#0C0C0C"
          d="M20.547 9.88L14.44 16l6.107 6.12-1.88 1.88-8-8 8-8 1.88 1.88z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1102_43082">
          <rect
            width="32"
            height="32"
            fill="#fff"
            rx="8"
            transform="matrix(1 0 0 -1 0 32)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowLeftIcon;
